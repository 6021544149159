/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryCodes, sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // delivery-method-ui-prod
    prod: 'https://7f4257475abc420f8a48367640d92740@o168195.ingest.sentry.io/6085888',
    // delivery-method-ui-nonprod
    nonprod: 'https://aebd4023e76a480697feacd3db9d32ff@o168195.ingest.sentry.io/6085894',
  },
})

export const logDynamicImportException = error => sentryException(error, {
  level: 'error',
  code: sentryCodes.DYNAMIC_IMPORT_REJECT,
})
