import { isProd, overrideTaxCbsServiceUrl, overrideTaxsysDomainForwardingUrl } from '@grantstreet/psc-environment/environment.js'
import type { Store } from 'vuex'
import { sentryException } from '../sentry.js'

export function setupFullPublicSite (store: Store<Record<string, unknown>>) {
  // Override the TCB service if needed. This is used by automatic sandbox
  // linking.
  if (process.env?.GSG_TAX_CBS_SERVICE) {
    if (isProd()) {
      sentryException(
        new Error('Overriding the TCB Service URL in prod is NOT recommended. This is likely a mistake.'),
        {
          level: 'critical',
        },
      )
    }
    overrideTaxCbsServiceUrl(process.env.GSG_TAX_CBS_SERVICE)
    overrideTaxsysDomainForwardingUrl(process.env.GSG_TAX_CBS_SERVICE)
  }
  store.dispatch('PayHub/setInstallFlags', {
    enableHeader: true,
    enableFooter: true,
    showAnnouncements: true,
    enableMyForms: true,
    enableHomePage: true,
    enableFloatingCart: true,
    enableMySettingsModification: true,
  })
}
