import Vue from 'vue'
import Banner from './banner.js'
import Notification from './notification.js'
import Footer from './footer.js'
import presetBannerOptions from './fixtures/presetBannerOptions.js'
import effectOptions from './fixtures/effectOptions.js'
import cannedModuleText from './fixtures/cannedModuleText.js'
import { configState, configGetters } from '@grantstreet/psc-config'

const constructors = {
  banners: Banner,
  notifications: Notification,
  footers: Footer,
}

const types = ['banners', 'notifications', 'footers']

const apiMethods = {
  banners: {
    retrieve: {
      admin: 'getBanners',
      public: 'getPublicBanners',
    },
    insert: 'insertBanner',
    update: 'updateBanner',
    delete: 'deleteBanner',
  },
  notifications: {
    retrieve: {
      admin: 'getNotifications',
      public: 'getPublicNotifications',
    },
    insert: 'insertNotification',
    update: 'updateNotification',
    delete: 'deleteNotification',
  },
  footers: {
    retrieve: {
      admin: 'getFooters',
      public: 'getPublicFooters',
    },
    insert: 'insertFooter',
    update: 'updateFooter',
    delete: 'deleteFooter',
  },
}

// Takes a string representing one of the announcements features.
// e.g. 'banners'
// Returns a options filtered to only include sites which have that feature
// enabled.
const clientSiteOptionsFactory = feature => state => {
  // Filter out sites that don't have this feature enabled
  const accessList = feature
    ? state.accessListsByFeature[feature]
    : configState.clientSiteAccessList

  // Create list of sites, for dropdowns, to which this user has access
  const clientSiteOptions = {}
  const { friendlyClientName, friendlySiteName } = configGetters
  for (const { client, site } of accessList) {
    if (!clientSiteOptions[client]) {
      clientSiteOptions[client] = {
        text: friendlyClientName(client, site),
        value: client,
        options: [],
      }
    }
    clientSiteOptions[client].options.push({
      text: friendlySiteName(client, site),
      value: `${client}/${site}`,
    })
  }

  return Object.values(clientSiteOptions)
}

const separateClientSites = (filters) => {
  const clients = []
  const sites = []
  for (const clientSite of filters.sites) {
    clients.push(clientSite.client)
    sites.push(clientSite.site)
  }
  filters.clients = clients
  filters.sites = sites
  return filters
}

export const getters = {
  jwt: state => state.jwt,

  // Initialization promise
  loadPromise: state => {
    if (state.loadPromise) {
      return state.loadPromise
    }
    throw new Error('Announcements/loadPromise is not initialized')
  },

  banners: state => state.banners,

  // State banners, notifications, and footers are all stored as hashes with
  // keys of the announcement's ID pointing to the announcement itself. Thus, we
  // need to call Object.values on it before we can use the find function for
  // arrays.
  banner: state => id => Object.values(state.banners).find(
    banner => banner.id === id,
  ),

  notifications: state => state.notifications,

  notification: state => id => Object.values(state.notifications).find(
    notification => notification.id === id,
  ),

  footers: state => state.footers,

  // Footers can be returned as null from the API, so we need to make a special
  // exception for this
  footer: (state) => {
    const client = configState.config.client
    const site = configState.config.site
    return state.footers
      ? Object.values(state.footers).find((footer) => footer.client === client && footer.site === site)
      : null
  },

  tags: state => state.tags,

  writeAccessByFeature: () => (feature) => configState.adminScope.includes(`admin:announcements:${feature}:write`),

  clientSiteOptions: clientSiteOptionsFactory(),

  clientSiteOptionsByFeature: (state) =>
    (feature) => clientSiteOptionsFactory(feature)(state),

  // Returns either a list of sites on which an announcement is displayed for
  // which the user does not have permissions, or returns false.
  missingPermissionsForAnnouncement: () => announcement => {
    const accessList = configState.clientSiteAccessList
    if (announcement instanceof Footer) {
      return accessList.find(({ client, site }) => client === announcement.client && site === announcement.site) || false
    }

    const missing = announcement.clientSites.filter(({ client, site }) =>
      !accessList.find(({ client: searchClient, site: searchSite }) => searchClient === client && searchSite === site),
    )

    return missing.length ? missing : false
  },

  computeClientSitesTextForAnnouncement: () => announcement => {
    const { friendlyClientName, friendlySiteName } = configGetters
    if (announcement instanceof Footer) {
      announcement.clientSiteText = friendlyClientName(announcement.client, announcement.site)
      announcement.clientSiteText += ' ' + friendlySiteName(announcement.client, announcement.site)
      return announcement
    }

    let activeForAllClients = true
    let aggregateAllSitesForAllClients = true
    const clientsText = []
    const sitesText = []

    const sitesPerClient = {}
    for (const { client, site } of announcement.clientSites) {
      if (!sitesPerClient[client]) {
        sitesPerClient[client] = {
          sites: [],
          activeForAllSites: true,
        }
      }
      sitesPerClient[client].sites.push(site)
    }

    // Check if each the announcement is selected for all clients and for all
    // sites, per client.
    // Loop though the list of all clients and sites checking if
    // they're in the announcement's selected list.
    for (const [clientKey, sites] of Object.entries(configState.masterSitesPerClient)) {
      const clientData = sitesPerClient[clientKey]

      // If the announcement is not selected on any sites for that client mark
      // activeForAllClients false and skip
      if (!clientData) {
        activeForAllClients = false
        aggregateAllSitesForAllClients = false
        continue
      }

      // If there are any sites (from the full list) the announcement is not
      // selected on, set activeForAllSites to false for this client and set the
      // aggregate to false.
      for (const siteKey of sites) {
        if (!clientData.sites.includes(siteKey)) {
          clientData.activeForAllSites = false
          aggregateAllSitesForAllClients = false
          break
        }
      }
      const { friendlyClientName, friendlySiteName } = configGetters
      // If the announcement is enabled for all sites on this client then simplify.
      const clientName = friendlyClientName(clientKey, sites[0])
      clientData.sitesText = clientData.activeForAllSites
        ? `All ${clientName} Sites`
        : `${clientName} ${
          clientData.sites
            .map(siteKey => friendlySiteName(clientKey, siteKey))
            .join(`, ${clientName} `)
        }`

      clientsText.push(clientName)
      sitesText.push(clientData.sitesText)
    }

    // If the announcement is enabled for all clients simplify the text.
    announcement.clientsText = activeForAllClients
      ? 'All Clients'
      : clientsText.join(', ')
    // If the announcement is enabled for all sites *on all clients* then simplify.
    announcement.sitesText = aggregateAllSitesForAllClients
      ? 'All Sites'
      : sitesText.join(', ')

    return announcement
  },
}

export const mutations = {
  setJwt (state, decodedJwt) {
    state.jwt = decodedJwt
  },

  setLoadPromise (state, loadPromise) {
    Vue.set(state, 'loadPromise', loadPromise)
  },

  setListByType (state, { type, announcements = {} }) {
    if (!type) {
      throw new TypeError('"type" is required.')
    }
    Vue.set(state, type, announcements)
  },

  setByType (state, { type, announcement = {} }) {
    if (!type || !announcement) {
      throw new TypeError('Both arguments are required.')
    }
    Vue.set(state[type], announcement.id, announcement)
  },

  removeByType (state, { type, announcement = {} }) {
    if (!type || !announcement) {
      throw new TypeError('Both arguments are required.')
    }
    Vue.delete(state[type], announcement.id)
  },

  localizeAnnouncements (state, locale) {
    for (const type of types) {
      const announcements = state[type]
      if (!announcements) {
        continue
      }
      Object.values(announcements).forEach((announcement) => {
        announcement.locale = locale
      })
    }
  },
}

export const actions = {
  async retrieveTags ({ state }) {
    state.tags = state.accessListsByFeature.notifications.reduce((tags, { client, site }) => {
      tags[`${client}/${site}`] = configState.allConfigs[client][site]?.announcements?.tags || []
      return tags
    }, {})
  },

  async retrieveByType ({ getters, rootGetters, commit }, { type, filters = null, access = 'public' } = {}) {
    try {
      // Get announcements
      const api = rootGetters['API/announcements']

      const method = apiMethods[type].retrieve[access]
      let { data: announcements } = await api[method](filters)

      const constructor = constructors[type]

      if (announcements) {
        const announcementsMap = {}

        // All announcements are returned as an array for admin and public API
        // calls EXCEPT for public footers -- this either returns an object or
        // null
        if (Array.isArray(announcements)) {
          for (let announcement of announcements) {
            announcement = new constructor(announcement)
            if (filters.admin) {
              // Don't unnecessarily compute text that requires searching allConfigs
              announcement = getters.computeClientSitesTextForAnnouncement(announcement)
            }
            announcementsMap[announcement.id] = announcement
          }
        }
        else {
          let announcement = new constructor(announcements)
          if (filters.admin) {
            announcement = getters.computeClientSitesTextForAnnouncement(announcement)
          }
          announcementsMap[announcement.id] = announcement
        }
        announcements = announcementsMap
      }
      else {
        announcements = {}
      }

      commit('setListByType', { type, announcements })
      return announcements
    }
    catch (error) {
      console.error(`Error retrieving ${type}:`, error.response)
      throw error
    }
  },

  computeAccessListPerFeature: ({ state }) => {
    const accessByFeature = {}
    const siteUsesFeature = configGetters.siteUsesAnnouncementsFeature
    for (const feature of types) {
      if (!accessByFeature[feature]) {
        accessByFeature[feature] = []
      }
      for (const { client, site } of configState.clientSiteAccessList) {
        if (
          siteUsesFeature({
            config: configState.allConfigs[client][site],
            feature,
          }) &&
          configState.adminScope.includes(`admin:announcements:${feature}:read`)
        ) {
          accessByFeature[feature].push({
            client,
            site,
          })
        }
      }
    }
    Vue.set(state, 'accessListsByFeature', accessByFeature)
  },

  async upsertByType ({ getters, rootGetters, commit }, { type, announcement }) {
    const api = rootGetters['API/announcements']

    const method = apiMethods[type][announcement.id ? 'update' : 'insert']
    const { data: newAnnouncement } = await api[method](announcement)

    if (!announcement.id) {
      announcement.id = newAnnouncement.id
    }
    await commit('setByType', {
      announcement: getters.computeClientSitesTextForAnnouncement(announcement),
      type,
    })
  },

  async deleteByType ({ rootGetters, commit }, { type, announcement }) {
    const api = rootGetters['API/announcements']

    const method = apiMethods[type].delete
    const { data: newAnnouncement } = await api[method](announcement.id)

    if (!announcement.id) {
      announcement.id = newAnnouncement.id
    }

    await commit('removeByType', {
      announcement,
      type,
    })
  },

  async fetchAdminData ({ getters, state, dispatch }, filters) {
    state.presetBannerOptions = presetBannerOptions
    state.effectOptions = effectOptions
    state.cannedModuleText = cannedModuleText
    state.moduleOptions = Object.values(configState.moduleList).map(({ name, id }) => ({
      text: name,
      value: id,
    }))

    filters.admin = true
    if (filters.hasOwnProperty('sites')) {
      filters = separateClientSites(filters)
    }

    const jwt = getters.jwt
    await Promise.all([
      ...types.map(type => {
        if (jwt.scp.includes(`admin:announcements:${type}:read`)) {
          if (type === 'notifications') {
            filters.includeInactive = true
          }
          return dispatch('retrieveByType', {
            filters,
            type,
            access: 'admin',
          })
        }
        return undefined
      }),
      dispatch('retrieveTags'),
    ])
  },

  async fetchPublicData ({ dispatch, commit }, { filters, locale = 'en' } = {}) {
    await Promise.all(types.map(type =>
      dispatch('retrieveByType', {
        filters,
        type,
        access: 'public',
      })),
    )

    commit('localizeAnnouncements', locale)
  },
}

export const state = {
  jwt: {},
  banners: {},
  notifications: {},
  footers: [],
  tags: {},
  accessListsByFeature: types.reduce((map, type) => {
    map[type] = []
    return map
  }, {}),
  presetBannerOptions: [],
  effectOptions: [],
  loadPromise: null,
}

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,

  strict: process.env.NODE_ENV !== 'production',
}
