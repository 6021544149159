import { mapByKey } from './objects.ts'

// Returns a function that sorts objects ASC by the passed prop
export function sortByProp (prop, transform) {
  return (a, b) => {
    if (transform) {
      a = transform(a)
      b = transform(b)
    }
    return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0
  }
}

// Case insensitive string search
export const caseInsensitive = (a, b) => {
  a = a.toLowerCase()
  b = b.toLowerCase()
  if (a < b) {
    return -1
  }
  if (b < a) {
    return 1
  }
  return 0
}

// Fuzzy match strings
export const fuzzyMatch = (query, option) => {
  // Build a regex that searches the string for each caracter in the query (in
  // any position)
  const pattern = query.split('').map((char) => {
    // If the character is a regex special char escape it
    if ('[\\^/$.|?*+()[]{}'.includes(char)) {
      char = '\\' + char
    }
    return `(?=.*${char})`
  }).join('')
  // Search for each character anywhere in the string, case insensitive
  const regex = new RegExp(pattern, 'gi')
  return option.match(regex)
}

// Array difference (symmetrical)
export const difference = (a, b) => a.filter(i => !b.includes(i)).concat(
  b.filter(i => !a.includes(i)),
)

// Array intersection
export const intersection = (a, b) => a.filter(i => b.includes(i))

/**
 * @function reorderByPropIndex
 * Takes an array of objects and reorders them using an ordered array of one
 * property found on the objects. `orderedValues` is an array of values found on
 * the objects under `key`.
 *
 * "Take objects, where the set object[key] is equal to the set orderedValues,
 * and reorder objects (indexed by key) to match the order of orderedValues."
 *
 * @param  {Array} objects       - The objects to be ordered.
 * @param  {Array} orderedValues - The list of ordered properties.
 * @param  {string} key          - The key used for ordering.
 * @return {Array}               - The reordered objects.
 */
export const reorderByPropIndex = (objects, orderedValues, key) => {
  const mapped = mapByKey(objects, key)
  return orderedValues.map(k => mapped[k])
}

/**
 * @function shuffle
 * Returns a Fisher–Yates Shuffled copy of passed array.
 * https://bost.ocks.org/mike/shuffle/
 * @param  {Array} array - The array to be copied and have its copy shuffled.
 * @return {Array}       - A shuffled copy of array.
 */
export const shuffle = array => {
  // XXX: There is a type wrapper for this in govhub-ui's test utils. Remove
  // that when psc-js gets converted to ts.

  // I know the point of Fisher–Yates is that it has good performance *in-place*
  // but mutating things is bad and this is a quick and simple implementation.
  const shuffled = [...array]
  let index = shuffled.length

  // Walk down the length of the array
  while (index !== 0) {
    // Pick a random element
    const random = Math.floor(Math.random() * index)
    // Math.floor is faster than Math.round so use floor and decrement after
    index--
    // And swap it with the current element.
    ;[shuffled[index], shuffled[random]] = [shuffled[random], shuffled[index]]
  }
  return shuffled
}
