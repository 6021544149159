// The BASE_URL is its unique version ID.
// However, based on what URL the user is currently hitting,
// we may want the base URL to change.
// If the user is just viewing the root location (static-build.govhub.com),
// the base URL should be nothing.
// If the user is looking at a specific version, then the base URL
// should contain that version.
// If the version the user is looking at is current, we should keep it as current.
export const currentBaseUrl = () => {
  if (window.location.pathname.includes('/govhub/v')) {
    return window.location.pathname.includes('/govhub/v/current') ? '/govhub/v/current/' : process.env.BASE_URL || '/'
  }
  return '/'
}
