<template>
  <LoadingBars
    v-if="isLoading"
    class="p-6"
  />
  <div
    v-else
    class="container container-fluid h-100 d-flex align-items-center justify-content-center"
  >
    <div
      class="d-flex flex-column align-items-center"
      style="max-width: 700px"
    >
      <div class="col-12 col-md-8 my-4">
        <h3
          class="banner mt-3 text-center"
          style="font-size: 2rem"
        >
          {{ $t('login.verification.enterCode') }}
        </h3>
        <div class="p-3 bg-white rounded">
          <p
            v-if="errorMessage"
            class="alert alert-danger"
          >{{ errorMessage }}</p>

          <form
            id="email-verify-form"
            @submit.prevent="submitCode"
          >
            <p class="mb-3">
              {{ $t('login.verification.details') }}
            </p>
            <div class="form-group d-flex align-items-center mb-3">
              <input
                v-model="code"
                type="password"
                autocomplete="off"
                class="form-control mr-2"
                name="token"
                style="flex: 3;letter-spacing: 2px;"
              >
              <button
                type="submit"
                class="btn btn-primary"
                style="flex: 1;"
              >
                {{ $t('submit.label') }}
              </button>
            </div>
          </form>

          <button
            v-if="!resentCode"
            type="submit"
            class="btn btn-link p-0 text-decoration-underline text-primary small-text"
            style="font-size: smaller"
            @click="resendCode"
          >
            {{ $t('login.verification.resendCode') }}
          </button>
          <div
            v-else
            class="d-flex align-items-center small-text"
            style="font-size: smaller"
          >
            <p>{{ $t('login.verification.newCode') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import LoginApi from '@grantstreet/login-api'
import { sentryException } from '@grantstreet/govhub-vue/src/sentry.js'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { useLogin } from '@grantstreet/login'
import { useCallbackActions } from '@grantstreet/callback-actions'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n()
const { initAuth } = useLogin()
const store = useStore()
const { runCallbacks } = useCallbackActions()

const loginApi = new LoginApi({ exceptionLogger: sentryException })

const email = route.query.email
const provider = route.query.provider
const clientId = route.query.clientId
const issuer = decodeURIComponent(route.query.issuer)
const callbackPath = route.query.callbackPath

const code = ref('')
const errorMessage = ref('')
const resentCode = ref(false)
const isLoading = ref(false)

const submitCode = async () => {
  errorMessage.value = ''
  resentCode.value = false
  isLoading.value = true

  try {
    await loginApi.verifyTokenExchangeEmail(email, provider, code.value)

    initAuth()
    await store.getters['PayHub/authPromise']

    // Run callback actions
    await runCallbacks()

    await router.push({ path: callbackPath })
  }
  catch (error) {
    if (error?.response?.status === 403) {
      errorMessage.value = t('login.verification.error.invalid')
    }
    else {
      errorMessage.value = t('login.verification.error.unexpected')
      console.error(`Unexpected error verifying email verification code: ${error}`)
    }
  }
  finally {
    code.value = ''
    isLoading.value = false
  }
}

const resendCode = async () => {
  errorMessage.value = ''
  code.value = ''
  isLoading.value = true

  try {
    await loginApi.isTokenExchangeEmailVerified(email, clientId, issuer, locale.value)
    resentCode.value = true
  }
  catch (error) {
    errorMessage.value = t('login.verification.error.unexpected')
    console.error(`Unexpected error resending email verification code: ${error}`)
  }
  finally {
    isLoading.value = false
  }
}

</script>
