import createPaymentsStore from './store.ts'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import { useGsgUser } from '@grantstreet/user'
import MyPaymentsApi from './api-client.js'
import type { Store } from 'vuex'
import type { SentryMessageFunction } from '@grantstreet/sentry'

export default function install (app, {
  store,
  sentryException,
} : {
  store: Store<Record<string, unknown>>
  sentryException: SentryMessageFunction
}) {
  store.commit('API/setMyPaymentsApi', MyPaymentsApi)
  if (!store.state.MyPayments) {
    const { user } = useGsgUser(app)
    store.registerModule('MyPayments', createPaymentsStore({ user }))
  }

  loadTranslations(sentryException)
}
