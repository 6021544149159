/**
 * Client for the Cart service API
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl, basePayHubUrl, baseCountyTaxesUrl } from '@grantstreet/psc-environment'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.apiVersion = 'v3'

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = process.env?.GSG_CART_SERVICE || `${baseGovHubUrl}/svc/cart`
    // Remove any trailing '/api/v3' on the URL, in case the
    // GSG_CART_SERVICE envvar has it. This is because the Go Cart
    // client requires it in the envvar at the moment, but this API
    // client forbids it.
    this.baseUrl = this.baseUrl.replace(/\/api\/v3\/?$/, '')

    // Cleanup ticket is PSC-23000 if we want to remove this same domain routing
    const domain = typeof window !== 'undefined' ? window.location.hostname : ''
    if (domain.match(/pay-hub/)) {
      this.sameDomainUrl = process.env?.GSG_CART_SERVICE || `${basePayHubUrl}/svc/cart`
    }
    else if (domain.match(/county-taxes/)) {
      this.sameDomainUrl = process.env?.GSG_CART_SERVICE || `${baseCountyTaxesUrl}/svc/cart`
    }
    else {
      this.sameDomainUrl = process.env?.GSG_CART_SERVICE || `${baseGovHubUrl}/svc/cart`
    }
    // Remove any trailing '/api/v3' on the URL, in case the
    // GSG_CART_SERVICE envvar has it. This is because the Go Cart
    // client requires it in the envvar at the moment, but this API
    // client forbids it.
    this.sameDomainUrl = this.sameDomainUrl.replace(/\/api\/v3\/?$/, '')
  }

  // Don't log cart 404s to Sentry, since this is a common expected case when a
  // user reloads an expired cart page.
  isException (error) {
    if (
      // Match /carts/$client/$site/$cartId and some sub-paths
      /\/carts\/[^/]+\/[^/]+\/[^/]+(\/?$|\/items|\/get_tokens)/.test(error?.config?.url) &&
      error?.response?.status === 404
    ) {
      return false
    }

    return true
  }

  ping () {
    return this.get(`/api/${this.apiVersion}/ping`)
  }

  // Opts may contain:
  //
  //   cart_id: anonymous cart that the user wants to "claim".
  //   create:  if cartId isn't found, create a new cart.
  //   merge:   whether to merge the user's pre-existing cart (if any)
  //            with the cart referenced by cartId. If this is false,
  //            the user will just continue using cartId.
  //
  vivifyCart (client, site, opts = {}) {
    // TODO: PSC-20244 Figure out a longer term solution
    // For Bulk carts ~3000 items, a user can easily hit the default timeout of 60
    // seconds due to downloading the large carts if they have a slower connection.
    // This provides a worse user experience for normal users but is necessary
    // to prevent bulk users ending up in a bad state.
    const options = { timeout: 90_000 }
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/vivify`, opts, options)
  }

  createCart (client, site, opts = {}) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}`, opts)
  }

  // It's a little bit awkward that the path is client/site + id, rather
  // than just id. Probably the store methods that make the API calls
  // will have the client/site as global state, though, and so the
  // actual UI code won't need to worry about it.
  getCart (client, site, cartId) {
    return this.get(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}`)
  }

  updateCart (client, site, cartId, cart) {
    return this.put(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}`, cart)
  }

  claimCart (client, site, cartId) {
    return this.patch(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/claim`)
  }

  deleteCart (client, site, cartId) {
    return this.delete(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}`)
  }

  emptyCart (client, site, cartId) {
    return this.delete(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/items`)
  }

  addToCart (client, site, cartId, items) {
    // Custom timeouts based on the number of items being added. This is mainly
    // to facilitate adding massive numbers of individual items to a user's cart
    // in a single request.
    //
    // The default Axios timeout is 60 seconds (60,000 milliseconds), which
    // continues here.
    //
    // TODO: PSC-19826 should look into making the timeouts based on request
    // size rather than by item counts.
    const options = { timeout: 60_000 }
    const numItems = items.length

    if (numItems >= 500) {
      const multiplier = Math.floor(numItems / 500)

      // Add 30 seconds of time per 500 items
      const timeout = (multiplier * 30_000) + 60_000
      options.timeout = timeout
    }
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/items`, items, options)
  }

  // This differs from `addToCart` in the Cart Service will add the given items
  // to a user's cart without checking existing items for updates.
  addToCartSkipUpdates (client, site, cartId, items) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/items_skip_updates`, items)
  }

  updateCartItem (client, site, cartId, item) {
    return this.patch(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/items/${item.id}`, item)
  }

  removeFromCart (client, site, cartId, itemId) {
    return this.delete(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/items/${itemId}`)
  }

  modifyCart (client, site, cartId, cart) {
    return this.patch(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}`, cart)
  }

  lockCart (client, site, cartId) {
    const lockCart = {
      items: [],
      locked: true,
    }
    return this.patch(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}`, lockCart)
  }

  expireCart (client, site, cartId) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/expire`)
  }

  // This is technically a legacy endpoint, but was kept around in Cart v3 for use in the PayPal workflow.
  // Use calculateFees generally to fetch fees for all tender types at once.
  calculateFeesSingleTender (client, site, cartId, payment) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/fee`, { type: payment.type })
  }

  calculateFees (client, site, cartId, tenderTypes) {
    const res = this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/fees`, tenderTypes)
    return res
  }

  calculateItemFees (client, site, payablePath, amount) {
    return this.post(`/api/${this.apiVersion}/action/calculate_item_fees`, {
      client,
      site,
      'payable_path': payablePath,
      amount,
    })
  }

  calculateItemFeesSingleTender (client, site, cartId, payablePath, amount, tender) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/calculate_item_fee_single_tender`,
      {
        amount,
        'payable_path': payablePath,
        'ewallet_token': tender.ewalletToken,
        'tender_type': tender.type,
      },
    )
  }

  getTokens (client, site, cartId) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/get_tokens`)
  }

  getTokensForUser (client, site, cartId, userId) {
    return this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/get_tokens_for_user`, { userId })
  }

  getTransactions (client, site, cartId, tender) {
    // For Bulk carts ~3000 items, a user can easily hit the default timeout of 60
    // seconds. This can be up to 18mb of data transferred back, so we'll
    // have an increased timeout here.
    const options = { timeout: 120_000 }
    const body = {
      'tender_type': tender.type,
    }

    if (tender.ewalletToken) {
      // eslint-disable-next-line camelcase
      body.ewallet_token = tender.ewalletToken
    }
    else if (tender.vaultToken) {
      // eslint-disable-next-line camelcase
      body.vault_token = tender.vaultToken
    }
    else if (tender.bankAccountNumber) {
      // eslint-disable-next-line camelcase
      body.bank_account_number = tender.bankAccountNumber
      // eslint-disable-next-line camelcase
      body.bank_account_type = tender.bankAccountType
    }

    const res = this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/transactions`, body, options)
    return res
  }

  checkOut (client, site, cartId, payment) {
    // don't time out checkout requests
    const res = this.postSameDomain(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/check_out`, payment, {
      timeout: 0,
      turnstileProtected: true,
    })
    return res
  }

  async createPaypalOrder (client, site, cartId, payment) {
    const payload = {
      delivery: payment.delivery,
      // eslint-disable-next-line camelcase
      fee_amount: payment.fee_amount,
      // eslint-disable-next-line camelcase
      paypal_brand: payment.paypal_brand,
    }

    if (payment.billing) {
      payload.billing = payment.billing
    }

    const res = await this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/create_paypal_order`, payload)

    return res.data.order_id
  }

  async approvePaypalOrder (client, site, cartId, orderId, contactPreference = 'email', loggedIn = false) {
    const payload = {
      'order_id': orderId,
      'contact_preference': contactPreference,
      'logged_in': loggedIn,
    }

    const res = await this.post(`/api/${this.apiVersion}/carts/${client}/${site}/${cartId}/capture_paypal_order`, payload)

    return res.data
  }
}
