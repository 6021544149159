import store, { InstallParams } from './store/index.ts'
import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'
import type { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'

type PluginInstallParams = {
  bus: EventBus
  logDiagnostics: (...args: Array<unknown>) => void
} & (
  | { config?: Setting, overrideParams: InstallParams }
  | { config: Setting, overrideParams?: InstallParams }
)

export default function install (_app, { bus, config, overrideParams, logDiagnostics } : PluginInstallParams) {
  store.commit('initialize', {
    payablesConfig: {
      client: config?.client,
      site: config?.site,
      payableSources: config?.payableSources?.payableSources || [],
      contactCountyText: config?.renewexpress?.contactCountyText,
      useDelivery: Boolean(config?.delivery?.meta?.enabled),
      enableRExHubPickUp: Boolean(config?.delivery?.enableRExHubPickUp),
      renewalServiceFee: config?.renewexpress?.renewalServiceFee,
      extendVehicleRegistrationSearch: config?.renewexpress?.extendVehicleRegistrationSearch,
      useInlineInsuranceAffidavits: config?.renewexpress?.useInlineInsuranceAffidavits,
      insuranceCommercialVehicleHelp: config?.renewexpress?.insuranceCommercialVehicleHelp,
      insuranceMilitaryPersonnelHelp: config?.renewexpress?.insuranceMilitaryPersonnelHelp,
      insuranceVIN: config?.renewexpress?.insuranceVIN,
      insuranceSignature: config?.renewexpress?.insuranceSignature,
      allowMilitaryRenewals: config?.renewexpress?.allowMilitaryRenewals,
      ...overrideParams?.payablesConfig,
    },
    eventBus: overrideParams?.eventBus || bus,
    logDiagnostics: overrideParams?.eventBus || logDiagnostics,
  })
}
