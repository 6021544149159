import { createI18n } from 'vue-i18n'
import en from './translations/en.ts'
import es from './translations/es.ts'
// We can't list @grantstreet/sentry as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import type { SentryMessageFunction } from '@grantstreet/sentry'

type Optional<T> = T | null | undefined

export type TranslatedText = Optional<string>

export type TranslatedTextObject = Partial<{
  en: TranslatedText
  es: TranslatedText
}>

export type TranslatedTextObjects = Record<string, Optional<TranslatedTextObject>>

export const isValidLocale = (locale: string): locale is 'en' | 'es' =>
  locale === 'en' || locale === 'es'

// Creates a plugin instance so that it can be accessed globally
export const i18n = (function () {
  let locale
  try {
    locale = (typeof localStorage !== 'undefined' && localStorage.getItem('payhubDefaultLocale')) || 'en'
  }
  catch (error) {
    console.error('Cannot access local storage due to incognito window')
    locale = 'en'
  }
  return createI18n<[typeof en | Record<string, string>], 'en' | 'es', false>({
    legacy: false,
    locale,
    fallbackLocale: 'en',
    warnHtmlMessage: false,
    // Prevent warnings every time a component falls back to default translations
    silentFallbackWarn: true,
    messages: {
      en: {},
      es: {},
    },
    // Silences warning for missing translations
    silentTranslationWarn: !!process.env.JEST_WORKER_ID,
  })
})()

let translationsLoaded = false

export const mergeLangs = ({ en, es }) => Object.fromEntries(
  [
    ...Object.keys(en),
    ...Object.keys(es),
  ]
    .filter((key, i, arr) => arr.indexOf(key) === i)
    .map(key => [key, { en: en[key], es: es[key] }]),
)

// Loads all translations if they haven't been loaded yet
export function loadTranslations (exceptionLogger: SentryMessageFunction) {
  if (translationsLoaded) {
    return
  }

  const translations = mergeLangs({ en, es })

  addI18n(translations, exceptionLogger)

  translationsLoaded = true
}

// Translates a list of items into a grammatical phrase given the current
// i18n.locale truncating if the number of items <= num.
// For example, with an input of ['foo', 'bear', 'bar'] this
// returns 'foo, bear, and bar' in English and 'foo, bear y bar' in Spanish.
// ['hi, hello, hey, ho, heya, ha'] returns 'hi, hello, hey, ho, heya...'
// Note this doesn't properly pay attention to vowels
export const truncatedTranslateList = (items, num = 5, spec = {}) => {
  if (!items || !items.length) {
    return ''
  }
  if (items.length <= num) {
    return translateList(items, spec)
  }

  // Clone for non-destructive editing
  items = [...items.slice(0, num)]
  return `${items.join(', ')}...`
}

export const truncatedTranslateAndList = (items, num) => truncatedTranslateList(items, num)

export const truncatedTranslateOrList = (items, num) => truncatedTranslateList(items, num, getMessageMap('or'))

// Translates a list of items into a grammatical phrase given the current
// i18n.global.locale.value. For example, with an input of ['foo', 'bear', 'bar'] this
// returns 'foo, bear, and bar' in English and 'foo, bear y bar' in Spanish.
// Note this doesn't properly pay attention to vowels
// See https://support.grantstreet.com/browse/PSC-6843
export const translateList = (items, spec: NonNullable<TranslatedTextObject> = {}) => {
  if (!items || !items.length) {
    return ''
  }
  if (items.length === 1) {
    return String(items[0])
  }

  // Clone for non-destructive editing
  items = [...items]

  let conjunction, separator, lastSeparator

  switch (i18n.global.locale.value) {
  case 'en':
    conjunction = spec.en || en.and
    separator = ','
    lastSeparator = ','
    break
  case 'es':
    conjunction = spec.es || es.and
    separator = ','
    lastSeparator = ''
    break
  default:
    console.error(`Unknown locale "${i18n.global.locale.value}`)
    return ''
  }

  if (items.length <= 2) {
    return items.join(` ${conjunction} `)
  }

  const lastItem = items.pop()
  return `${items.join(`${separator} `)}${lastSeparator} ${conjunction} ${lastItem}`
}

export const translateAndList = items => translateList(items)

export const translateOrList = items => translateList(items, getMessageMap('or'))

// Returns object single message keyed per locale
export const getMessageMap = (key: string): NonNullable<TranslatedTextObject> => ({
  en: en[key],
  es: es[key],
})

// Returns a requested default message map if the passed map is empty
export const useDefaultTranslation = (map: TranslatedTextObject, defaultMap: string | TranslatedTextObject) => {
  if (map && (typeof map.en !== 'undefined' || typeof map.es !== 'undefined')) {
    return map
  }
  return typeof defaultMap === 'string' ? getMessageMap(defaultMap) : defaultMap
}

// Adds exclamation points to a message or message map
export const bang = (message, locale = '') => {
  if (!locale) {
    return {
      // Convert to .map when we add more locales
      en: bang(message.en, 'en'),
      es: bang(message.es, 'es'),
    }
  }

  message = `${message}!`

  if (locale === 'es') {
    message = `¡${message}`
  }
  return message
}

export const detectKeyConflicts = (keys: Array<string>) => {
  const result = keys
    .map((key): [string, Array<string>] => {
      const regexp = new RegExp(`^${key.replaceAll('.', '\\.')}\\..+$`)
      return [key, keys.filter(key => regexp.test(key))]
    })
    .filter(([, matches]) => matches.length)

  return result
}

export const keyConflictError = (key, conflicts) =>
`Translation key: '${key}' has nested key conflicts.
Nested keys: ${conflicts.join(', ')}.
Please add '.default' to the end of '${key}' or otherwise ensure that it is not overridden by nested keys.`

const knownKeys: Array<string> = []
const knownConflicts: Array<string> = []

// Need to filter out special characters that conflict with the new
// messaging format syntax since some values come from site settings. See
// https://vue-i18n.intlify.dev/guide/migration/breaking.html#message-format-syntax
export const filterSpecialCharacters = (str: string) => str
  // @ - used for linked messages with optional modifiers.
  // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#linked-messages
  ?.replace(/@(?!(?:\..+)?:|'})/g, "{'@'}")
  // | - used for pluralization
  // https://vue-i18n.intlify.dev/guide/essentials/pluralization.html
  // We can't differentiate between escaped and unescaped characters in this
  // case, so we need to only filter data coming from site settings and we won't
  // be able to use pluralization syntax in site settings until those values are
  // updated with escaped versions
  .replace(/\|(?!'})/g, "{'|'}")

// Adds new i18n entries for the passed object which contains keys for
// each supported language
export const addI18n = (translations: TranslatedTextObjects, exceptionLogger: SentryMessageFunction) => {
  Object.entries(translations).forEach(([key, langMap]) => {
    if (!langMap) {
      return
    }

    knownKeys.push(key)

    i18n.global.mergeLocaleMessage('en', { [key]: langMap.en })
    // Default spanish translations to the english text if no translation was
    // provided
    i18n.global.mergeLocaleMessage('es', { [key]: langMap.es || langMap.en })
  })

  // We need to detect nested key conflicts at runtime because some of our keys
  // come from site settings and can't be checked in our pre push hook.
  const conflicts = detectKeyConflicts(knownKeys)
    .filter(([key]) => !knownConflicts.includes(key))

  conflicts.forEach(([key, conflicts]) => {
    knownConflicts.push(key)
    const error = keyConflictError(key, conflicts)
    console.warn(error)
  })

  if (conflicts.length) {
    const keys = conflicts.map(([key]) => key).join(', ')

    exceptionLogger(new Error(`Nested translation key conflicts detected: ${keys}`), {
      level: 'warning',
    })
  }
}
