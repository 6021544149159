import type { Store } from 'vuex'
import { currentBaseUrl } from '../static-build-helpers.ts'

// Parses the cartId from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted.
export async function setupCartIdFromRedirectUrl (store : Store<Record<string, unknown>>) {
  // We await the auth promise incase we're in the process of sending a newly
  // signed up user to a redirect from their confirmation email
  try {
    await store.getters['PayHub/authPromise']
  }
  catch (error) {}
  const urlForRedirectCartId = window.history.state.redirectCartId || window.location.pathname
  // For static builds, pull the id from /govhub/v/build_id/client/site/redirect/cart_id
  // or /govhub/v/build_id/client/redirect/cart_id
  // Otherwise, pull id from /:client/:site/redirect/:id or /:client/redirect/:id
  const currentUrl = currentBaseUrl()
  const match = currentUrl && currentUrl.match(/^\/govhub\/v\//)
    ? urlForRedirectCartId.match(/^\/govhub\/v(?:ersions)?(?:\/[^/]+){2,3}\/redirect\/([^/]+)/)
    : urlForRedirectCartId.match('^/[^/]+(?:/[^/]+)?/redirect/([^/]+)')
  return match ? match[1] : undefined
}
