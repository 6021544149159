import sleep, { createExponentialDebounce } from '@grantstreet/psc-js/utils/sleep.js'

/**
 * This file needs to stay separate form ./async-components.ts otherwise
 * playwright will throw a bunch of absolutely opaque parsing errors. I think
 * the issue is either that it can't parse vue files imported by ts or it can't
 * parse some of the extra scf sugar that might be enabled by our babel config.
 * Just a guess.
 */

// This will retry 6 times (so 7 requests total) with the last time at about
// 13230ms (cumulative; not accounting for the request duration). After the last
// iteration it will fully fail.
export const getAsyncImportDebounceFunction = () => createExponentialDebounce({ maxRetries: 5, scalar: 210, addNoise: true })

// Will retry a dynamic import at exponentially increasing intervals and throw
// if the final attempt fails.
export const getDynamicImportHandler = <T = unknown>(
  loader: () => Promise<T>,
  {
    onFail,
  }:
  {
    onFail?: (error: Error) => unknown
  } = {},
): () => Promise<T> => {
  // This is defined once so repeated calls to fetch it will fail if it's
  // already failed
  const getDebounce = getAsyncImportDebounceFunction()
  return async () => {
    while (true) {
      try {
      // Try the import
        return await loader()
      }
      catch (error) {
        try {
        // Wait a bit then retry. Throws when the final iteration has been
        // passed.
          await sleep(getDebounce())
          continue
        }
        catch {
        // If we can't try again expose and throw the error from the final
        // failed import
          await onFail?.(error instanceof Error ? error : new Error(String(error)))
          throw error
        }
      }
    }
  }
}
