import type { App } from 'vue'
import routerStore from './store/index.js'
import type { Store } from 'vuex'
import type { Router } from 'vue-router'

export default function install (_app : App<Element>, { store, router } : { store: Store<Record<string, unknown>>, router: Router}) {
  if (!store.hasModule('router')) {
    store.registerModule('router', routerStore)
  }

  store.commit('router/setRouter', router)
}
