import store, { InstallParams } from './store.ts'
import type { App } from 'vue'
import { useGsgUser } from '@grantstreet/user'
import { getPayableSource } from '@grantstreet/payables'
import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'

export default function install (app : App<Element>, { config, overrideParams } : { config?: Setting, overrideParams?: InstallParams }) {
  const { user } = useGsgUser(app)
  store.commit('initialize', {
    user,
    client: config?.client,
    site: config?.site,
    enableRExHubPickUp: Boolean(config?.delivery?.enableRExHubPickUp),
    usesRenewExpress: Boolean(config?.renewexpress?.meta?.enabled),
    displayMaskedAddress: Boolean(config?.delivery?.displayMaskedAddress),
    disableCustomAddress: Boolean(config?.delivery?.disableCustomAddress),
    enablePermanentAddressChange:
      Boolean(config?.delivery?.enableRExHubPermanentAddressChange),
    additionalRExHubPickUpFields:
      config?.delivery?.additionalRExHubPickUpFields || [],
    pickupInstructions: config?.delivery?.pickupInstructions,
    requireClickToShowAddress: Boolean(config?.delivery?.requireClickToShowAddress),
    contactPhone: getPayableSource('rex-vehicle-registration')?.contactPhone,
    ...overrideParams,
  })
}
