import Vue from 'vue'
import Vuex from 'vuex'
import ApiClass from './api.js'
import type { User } from '@grantstreet/user'
import { sentryException } from './sentry.ts'
import { searchPayablesPaths } from '@grantstreet/payables'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'

Vue.use(Vuex)

type VerifyStatus = {
    // uuidv4
    statusID: string
    accountPayableSavePath: string
    email: string
    // when true, the user will only be emailed, and will not be snail mailed.
    paperless: boolean
    createdAt: string
    deletedAt: string
    client: string
    site: string
    displayType: string
    payable: object | undefined
}

export type InstallPublicParams = {
    user: User
    client: string
    site: string
    logRequest: () => void
}

class State {
  loaded = false
  loadDataPromise: Promise<void> | undefined
  user: User | undefined
  client = ''
  site = ''
  logRequest: (() => void) | undefined
  api = new ApiClass({ exceptionLogger: sentryException })
  statuses: { [key: string]: VerifyStatus } = {}
}

const getters = {
  hasRequestedVerification: state => (accountPayableSavePath: string, returnVerifyStatus = false) => {
    const verifyStatus = state.statuses[accountPayableSavePath]
    const hasRequestedVerification = verifyStatus && verifyStatus.status === 'pin_requested'
    return returnVerifyStatus ? verifyStatus : hasRequestedVerification
  },
  hasVerified: state => (accountPayableSavePath: string, returnVerifyStatus = false) => {
    const verifyStatus = state.statuses[accountPayableSavePath]
    const hasRequestedVerification = verifyStatus && verifyStatus.status === 'payable_claimed'
    return returnVerifyStatus ? verifyStatus : hasRequestedVerification
  },
  hasLoaded (state: State) {
    return state.loaded
  },
}

const mutations = {
  initialize (state, {
    user,
    client,
    site,
    logRequest,
  }: InstallPublicParams) {
    state.user = user
    state.client = client
    state.site = site
    state.logRequest = logRequest
    state.api = new ApiClass({
      getJwt: () => user.getAccessToken(),
      exceptionLogger: sentryException,
    })
  },

  clearStatuses (state) {
    state.statuses = {}
  },

  setStatuses (state, statuses) {
    state.statuses = statuses
  },

  addToStatusesLocal (state, status) {
    state.statuses = {
      ...state.statuses,
      [status.accountPayableSavePath]: status,
    }
  },

  removeFromStatusesLocal (state, savePath) {
    const statusEntries = Object.entries(state.statuses)
      .filter(([_, value]) => value !== savePath)
    state.statuses = Object.fromEntries(statusEntries)
  },
}

const actions = {
  async loadData ({ state, commit }) {
    state.loadDataPromise ||= (async () => {
      commit('clearStatuses')

      if (!state.user?.loggedIn) {
        return
      }

      let { data: statuses = [] } = await state.api.getVerifyStatuses()

      statuses = statuses.filter((status: VerifyStatus) => {
        // All newly verified items will have a client/site stored in the
        // database, and all verify items in prod have a client/site stored in
        // the database, but some statuses in non-prod do not have a
        // client/site stored. These statuses will continue to be displayed on
        // all clients/sites because we couldn't identify where they originated
        // when we were backfilling data.
        if (!status.client || !status.site) return true

        return status.client === state.client && status.site === state.site
      })

      statuses = Object.fromEntries(statuses.map(
        (status: VerifyStatus) => [status.accountPayableSavePath, status],
      ))

      // Get the payables using paths from UV statuses and use in My Items
      ;(await searchPayablesPaths({
        paths: Object.keys(statuses),
        language: i18n.global.locale,
      })).forEach(payable => {
        const savePath = payable.raw.save_path
        if (payable && statuses[savePath]) {
          statuses[savePath].payable = payable
        }
      })

      commit('setStatuses', statuses)
      state.loaded = true
    })()

    await state.loadDataPromise
  },

  async requestVerification ({ state, commit }, { payable, accountPayableSavePath }) {
    const { data, status } = await state.api.requestVerification({
      payable,
      accountPayableSavePath,
      client: state.client,
      site: state.site,
    })

    if (status >= 400) {
      return { status, errorMessage: data?.displayMessage }
    }

    // accountPayableSavePath is the 'id' for a parent payable.
    // payable is used with My Items
    commit('addToStatusesLocal', { ...data, accountPayableSavePath, payable })
  },

  async enterVerification ({ state, commit }, { pin, payable, accountPayableSavePath }) {
    const { data, status } = await state.api.enterVerification({ payable, client: state.client, site: state.site, pin, accountPayableSavePath })

    if (status >= 400) {
      return { status, errorMessage: data?.displayMessage }
    }

    if (data) {
      // accountPayableSavePath is the 'id' for a parent payable.
      // payable is used with My Items
      commit('addToStatusesLocal', { ...data, accountPayableSavePath, payable })
    }
  },
}

export default new Vuex.Store({
  state: new State(),
  getters,
  mutations,
  actions,
})

export { State, getters, mutations, actions }
export type { VerifyStatus }
