<template>
  <div>
    <GovHubNav
      class="d-print-none"
      :nav-links="navLinks"
      :should-allow-redirect="shouldAllowRedirect"
      :logged-in="user.loggedIn"
      @redirect-click="$emit('redirect-click', $event)"
      @set-locale="setLocale"
      @log-in="handleLogin($event)"
      @log-out="handleLogOut"
    >

      <template #brand>
        <!-- TODO PSC-9031 check UseForms too -->
        <component
          :is="dynamicComponent"
          v-if="$route.params.client"
          data-test="home-link"
          class="d-block flex-shrink-0"
          :to="{
            name: 'home',
            params: $route.params,
          }"
          v-bind="anchorProps"
        >
          <ClientLogo class="nav-brand-p border-right nav-h" />
        </component>
      </template>

      <template #tools="{showLoginDisabledModal}">

        <LanguageLink
          class="mr-3 font-weight-light d-none d-lg-inline"
          :is-multilingual="isMultilingual"
          @set-locale="setLocale"
        />

        <NotificationPopper
          v-if="showAnnouncements && useAnnouncementsNotifications"
          class="mr-3"
          :tags="notificationTags"
        />

        <AccountDropdown
          v-if="useLogin"
          class="mr-2"
          :logged-in="user.loggedIn"
          :logout-redirect-url="logoutRedirectUrl"
          :dropdown-items="dropdownItems"
          @show-login-disabled-modal="showLoginDisabledModal"
          @log-in="handleLogin($event)"
          @log-out="handleLogOut"
        />

        <CartIconPopper
          v-if="!$route.meta.hideCartIcon && enableFloatingCart"
          placement="bottom"
          distance="10"
          popper-content-class="top-nav-mini-cart"
          inline
          transition-type="fade-count"
          class="nav-cart-icon my-2 d-inline text-primary"
          @continue="$router.push({
            name: 'checkout',
            params: $route.params,
          })"
        />

      </template>

    </GovHubNav>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GovHubNav from '@grantstreet/govhub-nav/src/components/GovHubNav.vue'
import ClientLogo from './ClientLogo.vue'
import LanguageLink from '@grantstreet/psc-vue/components/LanguageLink.vue'
import NotificationPopper from '@grantstreet/announcements-vue/src/components/public/NotificationPopper.vue'
import CartIconPopper from '@grantstreet/cart-vue/src/components/CartIconPopper.vue'
import AccountDropdown from '@grantstreet/govhub-nav/src/components/AccountDropdown.vue'
import { puddleCase } from '@grantstreet/psc-js/utils/cases.js'
import { mapConfigGetters, mapConfigState } from '@grantstreet/psc-config'
import { useGsgUser } from '@grantstreet/user'
import { useLoginHelpers } from '../login-helpers.js'

export default {
  emits: ['redirect-click'],
  components: {
    GovHubNav,
    ClientLogo,
    LanguageLink,
    NotificationPopper,
    CartIconPopper,
    AccountDropdown,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup: () => ({
    user: useGsgUser().user,
    handleLogin: useLoginHelpers().handleLogin,
    handleLogOut: useLoginHelpers().handleLogOut,
  }),

  computed: {

    notificationTags () {
      return this.$route.name ? [puddleCase(this.$route.name)] : []
    },

    logoutRedirectUrl () {
      return this.$store.getters['PayHub/logoutRedirectUrl'](this.$route)
    },

    dropdownItems () {
      return [{
        text: this.$t('topnav.dashboard'),
        icon: 'dashboard',
        props: {
          to: {
            name: 'my-dashboard',
            params: this.$route.params,
          },
        },
        // You can pass click handlers here as well
        // on: { click: () => {} },
      }]
    },
    homeUrl () {
      return this.config.payHub?.returnHomeUrl
    },

    shouldClientLogoRedirect () {
      return this.config.payHub?.clientLogoHomeUrl && this.config.payHub?.returnHomeUrl
    },

    dynamicComponent () {
      if (this.shouldClientLogoRedirect) {
        return 'a'
      }
      else if (this.siteUsesHomepage || this.siteUsesRedirectOnly) {
        return 'router-link'
      }
      return 'span'
    },

    anchorProps () {
      if (this.shouldClientLogoRedirect) {
        return {
          href: this.homeUrl,
        }
      }
      return {}
    },

    ...mapConfigState(['config']),

    ...mapState('PayHub', [
      'showAnnouncements',
      'enableFloatingCart',
    ]),

    ...mapConfigGetters([
      'useAnnouncementsNotifications',
      'useLogin',
      'siteUsesHomepage',
      'siteUsesRedirectOnly',
    ]),

    ...mapGetters('PayHub', [
      'navLinks',
      'shouldAllowRedirect',
      'isMultilingual',
    ]),
  },

  methods: {
    setLocale (locale) {
      this.$store.dispatch('PayHub/setLocale', { locale })
    },
  },
}

</script>

<style lang="scss" scoped>

.nav-cart-icon {
  margin-right: map-get($spacers, 3);
}
.nav-cart-icon:last-child {
  margin-right: map-get($spacers, 2);
}
.nav-cart-icon:first-child {
  // Use an explicit margin because the left handle of the cart makes it look
  // misaligned if we use a 2 spacer.
  margin-left: 1rem;
  margin-right: map-get($spacers, 4);
}

</style>
