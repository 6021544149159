<template>
  <ErrorPopper :error="error">
    <b-link
      href="#"
      data-test="remove-item"
      :disabled="disabled || $wait.waiting(`modifying.${cartItem.id}`)"
      @click.prevent="removeItem"
      @click="$emit('click')"
    >{{ $t("remove.default") }}</b-link>
  </ErrorPopper>
</template>

<script>
import ErrorPopper from '@grantstreet/psc-vue/components/ErrorPopper.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'

export default {
  emits: ['removed', 'click'],
  components: {
    ErrorPopper,
  },

  props: {
    cartItem: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      error: '',
    }
  },

  methods: {
    async removeItem () {
      this.$wait.start(`modifying.${this.cartItem.id}`)
      try {
        await this.$store.dispatch('Cart/removeFromCart', this.cartItem)
        EventBus.$emit('cart.removedItem', this.cartItem)
        this.$emit('removed')
      }
      catch (error) {
        this.error = error
      }
      this.$wait.end(`modifying.${this.cartItem.id}`)
    },
  },
}
</script>
