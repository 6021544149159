// This plugin installs the Cart & Checkout component into your Vue app.
//
// Usage:
//
//   import Cart from '@grantstreet/cart-vue'
//   import VueSVGIcon from @grantstreet/bootstrap/icons/vue-svgicon'
//
//   // Install prerequisites
//   Vue.use(VueSVGIcon)
//
//   // Install Cart
//   Vue.use(Cart)
import cartStore from './store/index.js'
import Cart from './components/Cart.vue'
import CartIconPopper from './components/CartIconPopper.vue'
import './styles/cart.scss'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import VueGtag from 'vue-gtag'
import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'
import { sentryException } from './sentry.js'
import type { App } from 'vue'
import { useGsgUser } from '@grantstreet/user'
import CartApi from './api-client.js'
import type { Store } from 'vuex'
import type { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'
import type { VueWaitInstance } from 'vue-wait'

type InstallParams = {
  store: Store<Record<string, unknown>>
  bus: EventBus
  cartId: string
}

export default function install (app: App<Element>,
  { store, bus, cartId = '' } : InstallParams,
) {
  const wait = app.config.globalProperties.$wait
  store.commit('API/setCartApi', CartApi)
  app.use(VueDOMPurifyHTML, sanitizeConfig)
  app.component('cart', Cart)
  // TODO: Is there still any reason to globally install this? Typically we
  // avoid that pattern in favor of local imports
  app.component('cart-icon-popper', CartIconPopper)
  // Register the Cart Vuex store
  if (!store.state.cart) {
    store.registerModule('Cart', cartStore)
  }

  loadTranslations(sentryException)
  // ---------------------------------------------------------------------------
  // Install plugins that weren't already installed by the parent app
  if (!app.config.globalProperties.$gtag) {
    app.use(VueGtag, {
      config: { id: getPayHubGaId() },
      // Don't report anything in sandboxes
      disableScriptLoad: process.env.NODE_ENV === 'development',
    })
  }
  // This is the initialization promise
  store.commit('Cart/setCartLoadPromise', loadCart({ store, wait, cartId, bus }))
}

export async function loadCart ({
  store,
  wait,
  cartId,
  bus,
  create = true,
  merge = true,
}: {
  store: Store<Record<string, unknown>>
  wait: VueWaitInstance
  cartId: string
  bus: EventBus
  create?: boolean
  merge?: boolean
}) {
  wait.start('loading cart')
  try {
    // Load cart as soon as the user and config are available.
    // Pass the cartId, if the caller specified one. (That will
    // happen during the initial redirect to PayHub.)
    await store.getters['PayHub/authPromise']
    await store.dispatch('Cart/loadCart', { create, merge, cartId })
  }
  finally {
    wait.end('loading cart')
  }
  // Switch carts when config.cart.client or config.cart.site changes
  bus.$on('config.configChanged', (config) => {
    const oldClient = store.getters['Cart/client']
    const newClient = config.cart.client
    const oldSite = store.getters['Cart/site']
    const newSite = config.cart.site
    if (oldClient !== newClient || oldSite !== newSite) {
      store.dispatch('Cart/loadCart', { create: true, merge: true })
    }
  })
}

// These events can be set to run after a page reload (usually from a login or
// signup). When that happens the necessary data is stashed and then unstashed
// and passed to these handlers on the subsequent load.
export const createCallbackActions = (app : App<Element>, { store } : { store : Store<Record<string, unknown>> }) => {
  const { user } = useGsgUser(app)
  return {
    // It's strange that *cart* has to process the event. But cart
    // is responsible for initializing the E-Wallet session, and
    // we need to do that in order to claim the tender.
    claimTender: async (data) => {
      if (!user.loggedIn) {
        console.warn('Cannot complete claimTender action. User is not logged in.')
        return
      }
      if (!data) {
        const error = new TypeError('Cannot claim tender, data was not provided.')
        sentryException(error)
        console.warn(error.message)
        return
      }
      await store.getters['Cart/cartLoadPromise']
      await store.getters['eWallet/loadPromise']
      return store.getters['API/ewallet'].claimTender(...data)
    },

    enrollInAutoPay: async ({ payablePath = '' } = {}) => {
      if (!user.loggedIn) {
        console.warn('Cannot complete enrollInAutoPay action. User is not logged in.')
        return
      }
      if (!payablePath) {
        const error = new TypeError('Cannot enroll in auto-pay, payablePath was not provided.')
        sentryException(error)
        console.warn(error.message)
        return
      }
      await store.getters['Cart/cartLoadPromise']
      store.commit('Cart/enrollPayableInAutoPay', payablePath)
    },

    autoEnrollRenewals: async () => {
      if (!user.loggedIn) {
        console.warn('Cannot complete autoEnrollRenewals action. User is not logged in.')
        return
      }
      await store.getters['Cart/cartLoadPromise']
      store.dispatch('Cart/autoenrollRenewalsInAutopay', true)
    },
  }
}
