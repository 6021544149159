<template>
  <!-- Changing this ID will break ChatBot.
  Update govHubNavHeight in Chatbot.vue if the ID is changed. -->
  <div
    id="govhub-nav"
    class="nav-h"
  >
    <div
      data-test="top-nav"
      class="top-nav d-flex align-items-center bg-white nav-h fixed-top"
    >

      <slot
        name="brand"
        :brand="brand"
      >
        <component
          :is="brand.to ? 'router-link' : 'span'"
          data-test="home-link"
          class="d-block flex-shrink-0"
          :to="brand.to"
        >
          <img
            v-if="brand.image"
            class="nav-brand-p border-right nav-h"
            :src="brand.image"
            :alt="brand.title"
          >
          <template v-else>
            {{ brand.title }}
          </template>
        </component>
      </slot>

      <SmartBurger
        v-if="navLinks"
        v-model="hamburgerActive"
        class="mt-lg-auto h-100 h-lg-auto"
        :nav-links="navLinks"
        :should-allow-redirect="shouldAllowRedirect"
        @redirect-click="$emit('redirect-click', $event)"
      />

      <div class="top-nav-tools d-flex flex-shrink-0 align-items-center ml-auto mb-lg-auto pt-2 pr-2">
        <slot
          name="tools"
          :show-login-disabled-modal="showLoginDisabledModal"
        >

          <LanguageLink
            data-test="language-link"
            class="mr-3 font-weight-light"
            :class="{
              'd-none d-lg-inline': navLinks?.length,
              'd-inline': !navLinks?.length,
            }"
            :is-multilingual="isMultilingual"
            @set-locale="$emit('set-locale', $event)"
          />

          <AccountDropdown
            v-if="useLogin"
            :logged-in="loggedIn"
            :logout-redirect-url="logoutRedirectUrl"
            :dropdown-items="dropdownItems"
            @show-login-disabled-modal="showLoginDisabledModal"
            @log-in="$emit('log-in', $event)"
            @log-out="$emit('log-out', $event)"
          />
        </slot>
      </div>

    </div>

    <LoginDisabledModal
      v-if="useLogin"
      ref="loginDisabledModal"
    />

    <div class="clearfix" />
  </div>
</template>

<script>
import SmartBurger from './SmartBurger.vue'
import LanguageLink from '@grantstreet/psc-vue/components/LanguageLink.vue'
import AccountDropdown from './AccountDropdown.vue'
import LoginDisabledModal from '@grantstreet/psc-vue/components/LoginDisabledModal.vue'
import brandLogo from '../assets/img/govhub.svg'

export default {
  emits: ['set-locale', 'redirect-click', 'input', 'log-in', 'log-out'],
  components: {
    SmartBurger,
    LanguageLink,
    AccountDropdown,
    LoginDisabledModal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: Object,
      validator: brand => typeof brand === 'object',
      default: () => ({
        to: '/',
        title: 'GovHub',
        image: brandLogo,
      }),
    },
    navLinks: {
      type: Array,
      default: undefined,
    },
    shouldAllowRedirect: {
      type: Function,
      default: () => true,
    },
    isMultilingual: {
      type: Boolean,
      default: true,
    },

    useLogin: {
      type: Boolean,
      default: true,
    },
    loggedIn: {
      type: Boolean,
      required: true,
    },
    logoutRedirectUrl: {
      type: String,
      default: null,
    },
    dropdownItems: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hamburgerActive: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    showLoginDisabledModal () {
      this.$refs.loginDisabledModal.show()
    },
  },
}

</script>

<style lang="scss" scoped>
.top-nav {
  box-shadow: 0 0 20px rgba(black, .10);
}

.top-nav-tools {
  min-height: 1.875rem;
}

.h-lg-auto {
  @include media-breakpoint-up(lg) {
    height: auto !important;
  }
}
</style>
