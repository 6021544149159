import createSchedPayStore from './store/index.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import { sentryException } from './sentry.js'
import { useGsgUser } from '@grantstreet/user'
import SchedPayApi from './api-client.js'
import type { App } from 'vue'
import type { Store } from 'vuex'

export default function install (app : App<Element>, {
  isAdmin,
  store,
} : { isAdmin: boolean, store: Store<Record<string, unknown>> }) {
  app.use(VueDOMPurifyHTML, sanitizeConfig)

  const { user } = useGsgUser(app)

  store.commit('API/setSchedPayApi', SchedPayApi)
  if (!store.state.SchedPay) {
    store.registerModule('SchedPay', createSchedPayStore({ user }))
  }

  // Create an async iffe
  // More efficient than a promise with callback nesting
  const promise = isAdmin ? Promise.resolve() : (async () => {
    await store.getters['PayHub/authPromise']
    if (!store.getters['Cart/client'] || !store.getters['Cart/site']) {
      console.warn("SchedPay can't load cart without client/site - aborting")
    }
    if (user.loggedIn) {
      await store.dispatch('SchedPay/retrieveUserSchedules')
    }
  })()

  store.commit('SchedPay/setLoadPromise', promise)

  // Add SchedPay translations
  loadTranslations(sentryException)
}

// These events can be set to run after a page reload (usually from a login or
// signup). When that happens the necessary data is stashed an then unstashed
// and passed to these handlers on the subsequent load.
export const createCallbackActions = (app: App<Element>, { store } : { store: Store<Record<string, unknown>>}) => {
  const { user } = useGsgUser(app)
  return {
    openSchedPay: ({ payablePath = '' } = {}) => {
      if (!user.loggedIn) {
        console.warn('Cannot complete openSchedPay action. User is not logged in.')
        return
      }
      if (!payablePath) {
        const error = new TypeError('Cannot open SchedPay. payablePath argument must be provided.')
        sentryException(error)
        console.warn(error.message)
        return
      }
      store.commit('SchedPay/setAutoloadPath', payablePath)
    },
  }
}
