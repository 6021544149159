// You can emit events:
//
//    EventBus.$emit('eventName', payload)
//
// Or register handlers:
//
//    EventBus.$on('eventName', data => { ... })
//
// The list of global events that can be emitted/accepted is located at
// @grantstreet/govhub-vue/README.md
// TODO  PSC-22937: Convert to typescript and move that list of events in here with types

import mitt from 'mitt'
const emitter = mitt()

export type EventBus = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $on: (type : string, handler: (...args: Array<any>) => unknown) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $once: (type : string, handler: (...args: Array<any>) => unknown) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $off: (type : string, handler: (...args: Array<any>) => unknown) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $emit: (type : string, ...args: Array<any>) => void
}

export const EventBus : EventBus = {
  $on: (type, handler) => emitter.on(type, handler),
  // polyfill for once. See: https://github.com/developit/mitt/issues/136
  $once: (type, handler) => {
    const wrappedHandler = (...args) => {
      emitter.off(type, wrappedHandler)
      handler(...args)
    }

    emitter.on(type, wrappedHandler)

    // Return wrapped handler so it is possible to remove with $off
    return wrappedHandler
  },
  $off: (type, handler) => emitter.off(type, handler),
  $emit: (type, handler) => emitter.emit(type, handler),
}
export default EventBus
