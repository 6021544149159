// For usage see @grantstreet/announcements-admin-ui/README.md

import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import announcementsStore from './store/index.js'
import Banners from './components/public/Banners.vue'
import NotificationPopper from './components/public/NotificationPopper.vue'
import FooterWidget from './components/public/FooterWidget.vue'
import FooterTitle from './components/public/FooterTitle.vue'
import { loadTranslations, i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { sentryException } from './sentry.js'
import { configState, configGetters } from '@grantstreet/psc-config'
import VueGtag from 'vue-gtag'
import AnnouncementsApi from './api-client.js'
import { Store } from 'vuex'
import type { App } from 'vue'
import { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'

import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'

type Module = {
  meta?: {
    enabled: boolean
  }
  [key: string]: unknown
}

type LoadAnnouncementsParams = {
  store: Store<Record<string, unknown>>
  config: Record<string, Module>
  locale: string
}

export default function install (app: App<Element>, { store, bus } : { store: Store<Record<string, unknown>>, bus?: EventBus }) {
  initBootstrapVue(app)

  // ---------------------------------------------------------------------------
  // Install plugins that weren't already installed by the parent app
  if (!app.config.globalProperties.$gtag) {
    app.use(VueGtag, {
      config: { id: getPayHubGaId() },
      // Don't report anything in sandboxes
      disableScriptLoad: process.env.NODE_ENV === 'development',
    })
  }

  // Install app
  app.component('Banners', Banners)
  app.component('NotificationPopper', NotificationPopper)
  app.component('FooterWidget', FooterWidget)
  app.component('FooterTitle', FooterTitle)

  // Register the Announcements Vuex store
  if (!store.state.Announcements) {
    store.registerModule('Announcements', announcementsStore)
  }

  // Set API
  // eslint-disable-next-line new-cap
  store.commit('API/setAnnouncementsApi', new AnnouncementsApi({ exceptionLogger: sentryException }))

  // ---------------------------------------------------------------------------
  // Add translations
  loadTranslations(sentryException)

  // Initialize
  // ---------------------------------------------------------------------------
  const initialPromise = (async () => {
    // Get config manually first time
    // load
    await loadAnnouncements({ store, config: configState.config, locale: i18n.global.locale.value })

    // Set up handler one time only
    if (!bus) {
      return
    }
    bus.$on('config.configChanged', (config) => {
      // Use passed config when reloading
      store.commit('Announcements/setLoadPromise', loadAnnouncements({ store, config, locale: i18n.global.locale.value }))
    })
    bus.$on('payhub.localeChanged', (locale) => {
      store.commit('Announcements/localizeAnnouncements', locale)
    })
  })()

  store.commit('Announcements/setLoadPromise', initialPromise)

  return true
}

async function loadAnnouncements ({ store, config, locale } : LoadAnnouncementsParams) {
  const { client, site } = config
  if (!client || !site) {
    return
  }

  if (!configGetters.siteUsesAnnouncements) {
    console.info(`Announcements not used in ${client}/${site} - not loading.`)
    return
  }

  const modules = Object.entries(config).reduce<string[]>((modules, [key, module]) => {
    if (module && module.meta && module.meta.enabled) {
      modules.push(key)
    }
    return modules
  }, [])

  await store.dispatch('Announcements/fetchPublicData', {
    filters: {
      sites: [ { client, site } ],
      methods: config.eWallet.allowedMethods,
      modules,
    },
    locale,
  }, {
    // Is this necessary?
    root: true,
  })
}
