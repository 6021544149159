import store, { InstallParams } from './store/index.ts'
import { useGsgUser } from '@grantstreet/user'
import type { App } from 'vue'
import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'

export default function install (app : App<Element>, { config, overrideParams } : { config?: Setting, overrideParams?: InstallParams }) {
  const { user } = useGsgUser(app)
  store.commit('initialize', {
    user,
    formConfig: config?.forms,
    ...overrideParams,
  })
}
