<template>
  <component
    v-bind="$attrs"
    :is="vueComponent"
    ref="payablesComponent"
  >
    <!-- Proxy any slots -->
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </component>
</template>

<script>
import { sentryException } from './sentry.ts'
import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin.ts'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },

  mixins: [
    createModuleLoaderMixin({
      moduleName: 'Payables',
      componentRefName: 'payablesComponent',
      supportedComponents: {
        'PayableDescription': () =>
          import('./components/description/PayableDescription.vue'),
        'FieldSearch': () =>
          import('./components/search/FieldSearch.vue'),
        'SearchResults': () =>
          import(/* webpackChunkName: "payables-search-results" */'./components/search/SearchResults.vue'),
        'FieldSearchREx': () =>
          import('./components/search/FieldSearchREx.vue'),
        'ExpandableList': () =>
          import('./components/search/ExpandableList.vue'),
        'ItemList': () =>
          import('./components/search/ItemList.vue'),
        'SelfServe': () =>
          import('./components/search/SelfServe.vue'),
        'PayableActionLinks': () =>
          import('./components/PayableActionLinks.vue'),
        'RExSearchResult': () =>
          import('./components/search/RExSearchResult.vue'),
      },
      exceptionLogger: sentryException,
    }),
  ],

  methods: {
    validate () {
      return this.$refs.payablesComponent.validate()
    },
  },
}
</script>
