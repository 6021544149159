import store, { InstallPublicParams } from './store.ts'
import type { App } from 'vue'
import { useGsgUser } from '@grantstreet/user'
import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'

type PluginInstallParams = {
  logRequest: (...args: Array<unknown>) => void
} & (
  | { config?: Setting, overrideParams: InstallPublicParams }
  | { config: Setting, overrideParams?: InstallPublicParams }
)

export default function install (app : App<Element>, { config, overrideParams, logRequest } : PluginInstallParams) {
  const { user } = useGsgUser(app)
  store.commit('initialize', {
    user,
    client: config?.client,
    site: config?.site,
    logRequest,
    ...overrideParams,
  })
}
