import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import createMyThingsStore from './store/index.js'
import { configGetters } from '@grantstreet/psc-config'
import { useGsgUser } from '@grantstreet/user'
import MyItemsApi from './api-client.js'
import type { SentryMessageFunction } from '@grantstreet/sentry'
import type { App } from 'vue'
import type { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'
import type { Store } from 'vuex'
import type { Router } from 'vue-router'

// This must be async so that it always returns a promise for loadPromise
const loadMySavedItems = async (store, user) => {
  if (!configGetters.useMySavedItems) {
    store.commit('MyItems/clearMyItemsLocal')
    return
  }

  if (user.loggedIn) {
    // Return the promise to await
    // This will throw an unhandled exception if we don't catch it,
    // which would result in MSI not successfully installing. By catching the
    // rejection exception, MSI will continue to function if a user's payable
    // are failed to be retrieved.
    try {
      return await store.dispatch('MyItems/loadMySavedItems')
    }
    catch (error) {
      store.commit('MyItems/setFailedToLoadUserPayables')
      return false
    }
  }
}

export let addToMyItems

type InstallParams = {
  bus: EventBus
  sentryException: SentryMessageFunction
  store: Store<Record<string, unknown>>
  router: Router
}

export default (app : App<Element>, {
  bus,
  sentryException,
  store,
} : InstallParams) => {
  store.commit('API/setMyItemsApi', MyItemsApi)

  const { user } = useGsgUser(app)

  if (!store.hasModule('MyItems')) {
    store.registerModule('MyItems', createMyThingsStore({ user }))
  }

  loadTranslations(sentryException)

  addToMyItems = ({ payable }) => store.dispatch('MyItems/addToMyItems', { payable })

  // Create an async iffe
  // More efficient than a promise with callback nesting
  const initialPromise = (async () => {
    // Get config manually first time
    // TODO: Stop depending on PH
    await store.getters['PayHub/authPromise']

    await loadMySavedItems(store, user)
    // Set up handler one time only
    bus.$on('config.configChanged', () => {
      // Use passed config when reloading
      store.commit('MyItems/setLoadPromise', loadMySavedItems(store, user))
    })

    bus.$on('cart.checkout', () => {
      store.commit('MyItems/setLoadPromise', loadMySavedItems(store, user))
    })

    // Listen for REx inline insurance affidavit update event.
    // Essentially swap the saved items - in this case "payable"
    // is the renewal that requires the affidavit and the "newPayable"
    // is the renewal that has the filled out affidavit information.
    bus.$on('update-insurance', async ({ payable, newPayable }) => {
      if (await store.getters['MyItems/isMyThing'](payable.savePath)) {
        store.commit('MyItems/removeFromMyItemsLocal', payable)
        store.commit('MyItems/addToMyItemsLocal', newPayable)
      }
    })
  })()

  store.commit('MyItems/setLoadPromise', initialPromise)
}
