import store from './store.ts'
import plugin from './install.ts'

// --- Plugin installer
export default plugin

// --- Dynamic Component Loader

export { default as DeliveryMethodLoader } from './DeliveryMethodLoader.vue'

// --- Methods

export const addAddress = address => store.dispatch('addAddress', address)

export const updateLastUsedAddress = addressId =>
  store.dispatch('updateLastUsedAddress', addressId)

export const resetTempAddresses = () => store.dispatch('resetTempAddresses')

// --- Models

export { default as Address } from './models/Address.ts'
