<template>
  <div
    class="landing-welcome nav-aware-min-h text-white text-center d-flex flex-column justify-content-center"
    :style="{ backgroundImage: `url('${coverSrc}')` }"
  >

    <div
      class="background-cover"
      :class="{ animate }"
    />

    <div class="gh-container p-2 w-100">
      <h1 :class="{ animate }">
        {{ title }}
      </h1>
      <b-row
        tag="nav"
        class="m-n2 justify-content-center"
        no-gutters
      >
        <b-col
          v-for="navLink in navLinks"
          :key="navLink.key"
          class="nav-link-col p-2 d-flex flex-column align-items-center justify-content-center"
          :class="{
            animate,
            'nav-link-col-short': navLinks.length >= 9
          }"
          sm="6"
          md="4"
          lg="3"
        >

          <NavLink
            :nav-link="navLink"
            class="nav-link flex-grow-1 p-2 p-sm-6 border border-white rounded-xl d-flex flex-column align-items-center justify-content-center"
            :class="{ animate }"
            :should-redirect="shouldRedirect"
            no-icons
            @redirect-click="$emit('redirect-click', $event)"
          >
            <template #default="{ text, isRedirect }">
              <div
                class="nav-link-content flex-grow-1 d-flex flex-column align-items-center justify-content-center"
                :class="{ animate }"
              >
                <FadeSVG
                  :key="`${navLink.key}-icon`"
                  :icon="navLink.icon"
                  color="#fff"
                  alt-color="#666"
                  height=""
                  width=""
                  timing="0.2s"
                  class="nav-link-icon mb-2 mb-sm-3 flex-grow-0 flex-shrink-0"
                />
                <div class="nav-link-text flex-grow-0 flex-shrink-0">
                  <span>
                    {{ text }}
                    <svgicon
                      v-if="isRedirect || navLink.type === 'external'"
                      :key="`${navLink.key}-external`"
                      class="redirect-icon"
                      icon="goto-xsmall"
                      color="#fff"
                      height="0.8rem"
                      width="0.8rem"
                    />
                  </span>
                  <span class="nav-link-text-hover text-primary-link">
                    {{ text }}
                    <svgicon
                      v-if="isRedirect || navLink.type === 'external'"
                      class="redirect-icon"
                      icon="goto-xsmall"
                      color="#666"
                      height="0.8rem"
                      width="0.8rem"
                    />
                  </span>
                </div>
              </div>
            </template>
          </NavLink>

        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import NavLink from '@grantstreet/govhub-nav/src/components/NavLink.vue'
import FadeSVG from '@grantstreet/psc-vue/components/FadeSvg.vue'

export default {
  emits: ['redirect-click'],
  components: {
    NavLink,
    FadeSVG,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    coverSrc: {
      type: String,
      required: true,
    },
    navLinks: {
      type: Array,
      required: true,
    },
    shouldRedirect: {
      type: Function,
      default: link => link,
    },
  },

  data: () => ({
    animate: false,
    doneAnimating: false,
    transitionHandle: null,
  }),

  mounted () {
    const img = new Image()

    img.src = this.coverSrc
    img.addEventListener('load', () => {
      this.animate = true
    })
    img.addEventListener('error', () => {
      this.animate = true
    })
  },
}

</script>
<style lang="scss" scoped>
.landing-welcome {
  position: relative;
  overflow: hidden;
  background-position: right;
  background-size: cover;
  z-index: 1;
  padding-bottom: 2.5rem;

  .background-cover {
    background-color: rgba(#000, 0.50);
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc((100vw + 100vh) * 2);
    height: calc((100vw + 100vh) * 2);
    z-index: -1;
    transition: transform 2.5s;
    transform: translateX(100%) translate(-50%, -50%) rotate(-30deg);

    &.animate {
      transform: translateX(0) translate(-50%, -50%) rotate(-30deg);
    }
  }

  h1 {
    color: white;
    font-size: 2.5rem;
    font-weight: 300;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s, opacity 0.5s, visibility 0.5s;
    transition-delay: 0.5s;

    @include media-breakpoint-up(sm) {
      font-size: 3.5rem;
    }

    &.animate {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.nav-link {
  position: relative;
  width: 100%;
  overflow: hidden;
  // TODO: Someday these should be refactored to use performant properties
  transition: width 0.3s, padding 0.3s, flex 0.3s, border-width 0.3s;
  z-index: 1;

  // Inverse logic here because we need to use !important.
  &:not(.animate) {
    width: 5px !important;
    flex: 0 1 5px !important;
    padding: 0 !important;
    border-width: 5px !important;
  }

  &:after {
    content: '';
    background-color: #fff;
    z-index: -1;
  }

  &:after,
  &-text-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &-text {
    position: relative;
    font-size: 1.25rem;
    color: #fff;
    width: 100%;
  }

  &-col {
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    height: 100px;

    // Staggers the transition start time of sibling elements of the same type.
    // Uses a base delay for all siblings and adds a static delay factor (see
    // above).
    // Note the caveats
    //   1. & (current selector) is a great default but if you are using this
    //      inside nested styles (e.g. .home .icon .foo) then the parent
    //      selectors will be duplicated as well.
    //   2. Siblings are limited to the max number expected. I.e. does not work
    //      0-n. 36 is arbitrary but I can't realistically see us using more
    //      than 36 navlinks on the page. Certainly not without development
    //      changes.
    //   3. :nth-of-type(#{$i}) might seem attaractive here but could easily be
    //      broken by changes that interleave same-type non-class siblings.

    $col-base: 0.3;
    $link-base: 0.5;
    $content-base: 0.65;

    $delay-factor: .15;
    $max: 36;

    // Element nesting is .nav-link-col .nav-link + .nav-link-content
    @mixin delay-rules ($i) {
      transition-delay: #{$col-base + ($delay-factor * $i)}s;

      .nav-link {
        transition-delay: #{$link-base + ($delay-factor * $i)}s;
      }

      .nav-link-content {
        transition-delay: #{$content-base + ($delay-factor * $i)}s;
      }
    }

    // Styles for first sibling
    & {
      @include delay-rules(0);
    }

    $selector-list: '&';
    // Specificity provides the override for following siblings
    @for $i from 1 through $max {
      & + #{$selector-list} {
        // transition-delay: #{$col-base + ($delay-factor * $i)}s;
        @include delay-rules($i);
      }

      // Append selector each time so the result resembles & + & + &
      // (which would be the third sibling)
      // Will break if you
      $selector-list: #{$selector-list} '+ &';
    }

    ////////////////////////////////////////////////////////////////////////////

    @include media-breakpoint-up(sm) {
      height: 225px;

      &-short {
        height: 175px;
      }
    }

    &.animate {
      opacity: 1;
      visibility: visible;
    }
  }

  &-content {
    flex: 1 0 100%;
    width: 100%;
    transition: transform 0.3s ease-out;
    transform: translateY(-200%);

    &.animate {
      transform: translateY(0);
    }
  }

  &:hover:after,
  &:hover .nav-link-text-hover,
  &:hover ::v-deep .svg-alt {
    opacity: 1;
  }

  &-icon {
    &, ::v-deep svg {
      width: 1rem;
      height: 1rem;

      @include media-breakpoint-up(sm) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.redirect-icon {
  vertical-align: baseline;
}
</style>
