import type { App } from 'vue'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import { loadTranslations, i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { sentryException } from '../sentry.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import '@grantstreet/bootstrap/icons/js/index.js'
import '../styles/index.js'

import type { User } from '@grantstreet/user'
import createApplicationStore from '../store/index.js'
import {
  type GsgLoginUtilities,
  useLogin as useLoginUtils,
} from '@grantstreet/login'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'

// APIs
import RequestApi from '@grantstreet/request-api'
import { Store } from 'vuex'

// This state type isn't good because createApplicationStore is still js. When
// we convert it to TS this can be defined internally.
type ApplicationState = ReturnType<typeof createApplicationStore>
export type ApplicationStore = Store<ApplicationState>

// Sets the user's language and loads PayHub's translations. If the user already
// has a locale saved in localStorage this uses that. If the user doesn't have
// a locale saved it will attempt to use the browsers default languages
const initI18n = () => {
  const defaultLocale = safeLocalStorage.getItem('payhubDefaultLocale') ||
    // Loop through browsers languages but default to en
    window.navigator.languages.find(lang => {
      const code = lang.slice(0, 2)
      return code === 'es' || code === 'en'
    })?.slice(0, 2) || 'en'

  if (defaultLocale) {
    i18n.global.locale.value = defaultLocale
  }
  loadTranslations(sentryException)
}

function initEventBus (store: Store<Record<string, unknown>>) {
  EventBus.$on('config.flagsChanged', async flags => {
    // LD flags can be used to enable a few basic announcements
    // setAnnouncement depends on the config
    store.dispatch('PayHub/setAnnouncement', { name: flags.announcement })
  })
}

export const setupPublicSite = (
  app: App<Element>,
  user: User,
) => {
  initI18n()

  // Bootstrap initialization
  initBootstrapVue(app)

  const store = createApplicationStore({
    user,
    updateUserData: data => useLoginUtils(app).updateUserData?.(data),
  } as {
        user: User
        updateUserData: NonNullable<GsgLoginUtilities['updateUserData']>
      })

  // Set locale from i18n
  store.commit('PayHub/setLocaleLight', i18n.global.locale.value)

  // Must be set for diagnostics logging to work
  store.commit('API/setRequestApi', RequestApi)

  initEventBus(store)

  return store
}
