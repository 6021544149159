import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import { GsgExternalLoginOptions, GsgFullLoginOptions } from '@grantstreet/login'
import type { User } from '@grantstreet/user'
import { GSG_ENVIRONMENT } from '@grantstreet/psc-environment'
import { currentBaseUrl } from '../static-build-helpers.ts'

/**
 * Return the options required to setup the "external" Login
 * implementation, which consumes a token from a trusted external
 * authentication provider to produce an internal authentication token
 * (also called "token exchange").
 */
export const setupEmbeddedLoginOptions = (user: User, config: Setting, embeddedWhisperer): GsgExternalLoginOptions => ({
  user,
  // @ts-expect-error TODO: SM-5344
  baseRoute: currentBaseUrl(),
  useExternalAuth: true,
  oidcClient: config.login.tokenExchangeClientId,
  getExternalJwt: () => embeddedWhisperer.message({
    action: 'gsgPublicSite.getJwt',
  }),
  handleLogin: () => embeddedWhisperer.notify({
    action: 'gsgPublicSite.handleLogin',
  }),
  userFieldMap: {
    name: 'name',
    givenName: 'given_name',
    familyName: 'family_name',
    phone: 'phone',
  },
})

/**
 * Return the options required to set up the "full" Login
 * implementation, which allows for direct authentication of users.
 */
export const setupFullLoginOptions = (user: User, config: Setting): GsgFullLoginOptions => ({
  env: GSG_ENVIRONMENT,
  user,
  baseRoute: currentBaseUrl(),
  useExternalAuth: false,
  oidcClient: 'payhub-spa',
  getAuthQueryParams: () => {
    // Pass client data for display on the login screen
    const {
      client,
      site,
      payHub: {
        clientTitle: clientDisplay = '',
        landingPageTitle: siteDisplay = '',
        clientLogo = '',
      } = {},
    } = config

    // Discover the user's language preference
    const locale = safeLocalStorage.getItem('payhubDefaultLocale')

    return {
      'client_site': `${client}/${site}`,
      'client_display': clientDisplay,
      'site_display': siteDisplay,
      'display_logo_url': clientLogo,
      'email_param_site': `${client}/${site}`,
      ...(locale ? { 'ui_locales': locale } : {}),
    }
  },
  userFieldMap: {
    name: 'name',
    givenName: 'given_name',
    familyName: 'family_name',
    phone: {
      current: 'https://govhub.com/phone',
      deprecated: 'https://pay-hub.net/phone',
    },
    contactPreference: 'contact_preference',
    language: {
      current: 'https://govhub.com/language',
      deprecated: 'https://pay-hub.net/language',
    },
  },
})
