import store, { InstallParams } from './store/index.ts'
import type { App } from 'vue'
import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'

export default function install (_app : App<Element>, { config, overrideParams } : { config?: Setting, overrideParams?: InstallParams}) {
  store.commit('initialize', {
    client: config?.client,
    site: config?.site,
    charitableDonations: config?.renewexpress?.charitableDonations,
    ...overrideParams,
  })
}
