import Vue from 'vue'
import Vuex from 'vuex'
import Payable from '../models/Payable.ts'
import BulkSearchPayables from '../models/BulkSearchPayables.ts'
import { orderPayablesByPaths } from '../utils.ts'
import { mapByKey } from '@grantstreet/psc-js/utils/objects.ts'
import PayablesApi from '../api-client.js'
import { sentryException } from '../sentry.ts'
import { TranslatedTextObject } from '@grantstreet/psc-vue/utils/i18n.ts'
import type { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'

const DEFAULT_LANGUAGE = 'en-US,en'

Vue.use(Vuex)

type PayablesConfig = {
  client: string
  site: string
  payableSources: object[]
  contactCountyText?: TranslatedTextObject
  useDelivery: boolean
  enableRExHubPickUp: boolean
  renewalServiceFee: string[] | undefined
  extendVehicleRegistrationSearch: boolean
  useInlineInsuranceAffidavits: boolean
  insuranceCommercialVehicleHelp?: TranslatedTextObject
  insuranceMilitaryPersonnelHelp?: TranslatedTextObject
  insuranceVIN: boolean
  insuranceSignature: boolean
  allowMilitaryRenewals: boolean
}

export type InstallParams = {
  payablesConfig: PayablesConfig
  eventBus: typeof EventBus
  logDiagnostics: (data) => void
}

class State {
  payablesConfig: PayablesConfig = {
    client: '',
    site: '',
    payableSources: [],
    contactCountyText: undefined,
    useDelivery: false,
    enableRExHubPickUp: false,
    renewalServiceFee: ['chargeMailFee', 'chargePickUpFee'],
    extendVehicleRegistrationSearch: false,
    useInlineInsuranceAffidavits: false,
    insuranceCommercialVehicleHelp: undefined,
    insuranceMilitaryPersonnelHelp: undefined,
    insuranceVIN: false,
    insuranceSignature: false,
    allowMilitaryRenewals: false,
  }

  eventBus: typeof EventBus | undefined
  logDiagnostics: ((data) => void) | undefined

  // The Payables API does not require a user JWT
  api = new PayablesApi({ exceptionLogger: sentryException })
}

export default new Vuex.Store({
  state: new State(),

  getters: {
    getPayableSource: (_state, getters) => displayType =>
      getters.mappedSources[displayType],

    // Used to avoid repeated .finds on the array
    mappedSources: (state) => mapByKey(state.payablesConfig.payableSources, 'displayType'),

    freeformFieldDefinitions: (_state, getters) => (payable) => {
      if (
        payable.freeformFields?.length &&
        getters.getPayableSource(payable.configDisplayType?.name)?.freeformFields?.length
      ) {
        sentryException(new Error(`Payable has freeform fields from both payables and site settings. Only one is permitted: ${payable.path}`))
      }

      // Freeform fields from payables takes priority over site settings
      return payable.freeformFields ||
      // Now check for site settings payable sources freeform fields
        getters.getPayableSource(payable.configDisplayType?.name)?.freeformFields ||
        []
    },

    mapFreeformFields: (_state, getters) => ({ payable, fieldMap }) => {
      return getters.freeformFieldDefinitions(payable).map(fieldMap)
    },

    // Takes a string displayType
    // and converts it to the displayType hash found in the config, eg.
    // "property-tax" becomes:
    //  {
    //    name: 'property-tax',
    //    title: 'sidebar.title.property',
    //    icon: 'property-tax',
    //  }
    inflateDisplayType: (state, getters) => ({ displayType }) => {
      let source = getters.mappedSources[displayType]

      if (!source) {
        // If there's only one payable source for the site, default to that. We
        // haven't always returned displayTypes for every payable, so this
        // could happen if we start migrating a site to use Payable Sources but
        // users still have cart items saved without displayTypes.
        if (state.payablesConfig.payableSources.length === 1) {
          source = state.payablesConfig.payableSources[0]
          console.warn(`No payable source found with display type "${displayType}" for ${state.payablesConfig.client} ${state.payablesConfig.site}. Falling back to the site's only payable source with display type "${source.displayType}".`)
        }
        else {
          // TODO PSC-3233 Turn this into a Sentry log once we stop trying to
          // load all of a user's receipts from any site in the current site
          // (which currently hits this conditional a whole lot). We do want
          // this to log to Sentry eventually to check for configuration errors,
          // though in the initial multi-department project we'll be conducting
          // thorough testing as we migrate sites to payable sources and should
          // catch these misconfigurations then.
          console.warn(`No payable source found with display type "${displayType}" for ${state.payablesConfig.client} ${state.payablesConfig.site}. Falling back to general type.`)
          source = {
            displayType: 'base',
            icon: 'cash-single',
            categoryTitle: {
              en: 'General',
              es: 'General',
            },
          }
        }
      }

      return {
        name: source.displayType,
        icon: source.icon,
        title: source.categoryTitle,
      }
    },
  },

  mutations: {
    initialize (state, params: InstallParams) {
      Object.assign(state, params)
    },
  },

  actions: {
    // 'data' is an object containing things to pass to the adaptor. It
    // should contain at least a 'query' object.
    async searchPayables ({ state, getters }, { payablesAdaptor, data = {}, language = DEFAULT_LANGUAGE }) {
      const {
        data: {
          payables: results = [],
          total = undefined,
        } = {},
      } = await state.api.search(payablesAdaptor, data, language)

      return {
        payables: results.map(raw => new Payable({
          raw,
          configDisplayType: getters.inflateDisplayType({ displayType: raw.display_type }),
        })),
        total,
      }
    },

    async searchPayablesPath ({ state, getters }, { path, language = DEFAULT_LANGUAGE }) {
      const { data: raw } = await state.api.find(path, language)

      return new Payable({
        raw,
        configDisplayType: getters.inflateDisplayType({ displayType: raw.display_type }),
      })
    },

    async searchPayablesPaths ({ state, getters }, { paths, language = DEFAULT_LANGUAGE }) {
      const { data: { payables: results = [], is_success: success } = {} } = await state.api.findMultiple(paths, language)

      if (success === 0 && results.length === 0) {
        throw new Error('Failed call to payables')
      }

      const inflatedPayables = results.map(raw => new Payable({
        raw,
        configDisplayType: getters.inflateDisplayType({ displayType: raw.display_type }),
      }))

      return orderPayablesByPaths({ payables: inflatedPayables, orderedPaths: paths })
    },

    // The bulkSearch API method returns something like this:
    // {
    //   payables: {
    //     identifier1: {
    //       results: [ payable1, payable2 ],
    //     },
    //     identifier2: {
    //       results: [ payable3 ],
    //     },
    //     identifier3: {
    //       results: [],
    //       error: { display_message: "Whomp whomp..." },
    //     },
    //   }
    // }
    async bulkSearchPayables ({ state, getters }, { payablesAdaptor, data = {}, language = DEFAULT_LANGUAGE }) {
      const { data: { payables } } = await state.api.bulkSearch(payablesAdaptor, data, language)

      return new BulkSearchPayables({
        payables,
        inflate: getters.inflateDisplayType,
      })
    },

    // The taxsysAddItems API methods returns something like this:
    // { reference_number: '123456789' },
    async taxsysAddItems ({ state }, { payablesAdaptor, data = {}, language = DEFAULT_LANGUAGE }) {
      // Adaptors from site settings end in /v0, which we need to remove to call
      // this "events" API endpoint
      payablesAdaptor = payablesAdaptor.replace(/\/v\d+$/, '')
      const { data: { reference_number: referenceNumber } } = await state.api.taxsysAddItems(payablesAdaptor, data, language)
      return referenceNumber
    },

    // XXX: This action is documented in its export and in the convert()
    // API function
    async createPayable ({ state, getters }, {
      adaptor,
      payable,
      accessToken = '',
      adminUserToken = '',
    }: {
      adaptor: string
      payable: object
      accessToken: string
      adminUserToken: string
    }) {
      const data = await state.api.convert(adaptor, payable, accessToken, adminUserToken)
      return new Payable({
        raw: data,
        configDisplayType: getters.inflateDisplayType({ displayType: data.display_type }),
      })
    },
  },
})
