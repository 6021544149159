import type { GoogleAnalytics } from '../embedded-demo/EmbeddedDemoUtils.js'
import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'

export function setupGoogleAnalyticsConfig ({ googleTagId, userId }: Partial<GoogleAnalytics>) {
  type GaTag = {
    id: string
    params?: {
      user_id: string
    }
  }
  type GaConfig = {
    config: GaTag
    includes?: GaTag[]
    disableScriptLoad: boolean
  }

  const config: GaConfig = {
    config: { id: getPayHubGaId() },
    disableScriptLoad: process.env.NODE_ENV === 'development',
  }

  // Log to secondary google analytics account if a tag is received
  if (googleTagId) {
    config.includes = [{
      id: googleTagId,
    }]

    // Log all GA events using the provided userId
    if (userId) {
      config.includes[0].params = {
        'user_id': userId,
      }
      config.config.params = {
        'user_id': userId,
      }
    }
  }

  return config
}
