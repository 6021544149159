import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import myStore from './store/index.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import { configState } from '@grantstreet/psc-config'
import HelpApi from './api-client.js'
import type { App } from 'vue'
import type { SentryMessageFunction } from '@grantstreet/sentry'
import type { EventBus } from '@grantstreet/psc-vue/utils/event-bus.ts'
import type { Store } from 'vuex'

type Topic = {
  text: {
    en?: string
    es?: string
  }
  defaultOnPage: string
  unselectable?: boolean
}

type InstallParams = {
  bus: EventBus
  sentryException: SentryMessageFunction
  store: Store<Record<string, unknown>>
}

export default function install (app : App<Element>, { bus, sentryException, store } : InstallParams) {
  app.use(VueDOMPurifyHTML, sanitizeConfig)

  store.commit('API/setContactApi', HelpApi)

  if (!store.state.Help) {
    store.registerModule('Help', myStore)
  }

  loadTranslations(sentryException)

  initializeQuestions(configState.config, store)

  // Rebuild the list of questions when config changes
  bus.$on('config.configChanged', config => {
    initializeQuestions(config, store)
  })
}

function initializeQuestions (config, store) {
  let topics : Array<Topic> = []

  if (config?.help?.topics?.length) {
    // the config topics already have the format { text, defaultOnPage }
    topics = config.help.topics
    // add the dropdown prompt
    topics.unshift({
      text: {
        en: 'Select a topic',
        es: 'Elige un tema',
      },
      defaultOnPage: '',
      unselectable: true,
    })
  }
  // If we have topics, add this generic topic to the top of them but below
  // the dropdown prompt.
  // If we have no topics, add this generic topic as the only element
  const index = topics.length ? 1 : 0
  topics.splice(index, 0, {
    text: {
      en: 'Website issue',
      es: 'Problema del sitio web',
    },
    defaultOnPage: '',
  })

  store.commit('Help/setTopics', topics)
}
