import { joinUrl } from '@grantstreet/psc-js/utils/urls.js'
// PSC ENVIRONMENT HELPERS
//
// This is the new official way to handle environments in PSC apps.
//
// NOTE: Many PSC apps still use legacy environment-detection methods, but we
// are migrating those to this new method. If you are modifying an existing app
// you should use whichever environment method it already uses. If you are
// creating a new app you should use this new method.
//
// How to use this file:
//
// 1. As soon as your app/widget initializes, determine the current environment
//    using one of the methods outlined in this page:
//    https://support.grantstreet.com/wiki/display/TECH/Front-End+Best+Practices
//
// 2. Validate the environment from step 1 by passing it to
//    isValidEnvironment(). If it is invalid, show a load/site error.
//
// 3. Pass the now-validated environment to setEnvironment(). This will set the
//    value of the GSG_ENVIRONMENT variable.
//
// 4. In your app, wherever you need to know the current environment (e.g., to
//    make an API request), import GSG_ENVIRONMENT and use it as a string.
//
// Supported PSC environments:
//   - dev
//   - test
//   - beta
//   - stage
//   - demo
//   - prod
//
// Note, the `dev` environment will behave like `test` when talking to back-end
// services, since there are no deployed `dev` PSC services.
//
// Avoid doing URL matching to determine the environment - that is not scalable
// when our widgets are embedded on arbitrary sites.
//

/**
 * A string of the current environment ('dev', 'test', 'stage', 'beta', 'demo',
 * or 'prod'), without the data center number
 * @type string
 */
export let GSG_ENVIRONMENT

/**
 * A string of the base GovHub URL for the current environment
 * @type string
 */
export let baseGovHubUrl

/**
 * A string of the base PayHub URL for the current environment
 * @type string
 */
export let basePayHubUrl

/**
 * A string of the base County Taxes URL for the current environment
 * @type string
 */
export let baseCountyTaxesUrl

/**
 * A string of the base E-Wallet URL for the current environment
 * @type string
 */
export let baseEWalletUrl

/**
 * A string of the base DataVault (DV) URL for the current environment
 * @type string
 */
export let baseDVUrl

/**
 * The environment to find PSC services. Separate from {@link
 * GSG_ENVIRONMENT} because the `dev` environment should behave
 * like `test` when talking to back-end services, since there
 * are no deployed `dev` PSC services.
 */
export let pscServiceEnvironment

export const environmentStrings = Object.freeze({
  dev: 'dev',
  test: 'test',
  stage: 'stage',
  beta: 'beta',
  demo: 'demo',
  prod: 'prod',
})

// Returns a boolean indicating whether the passed environment is one of the
// valid PSC environments.
export const isValidEnvironment = environment => typeof environmentStrings[environment] === 'string'

const getPscServiceEnvironment = (env = GSG_ENVIRONMENT) => {
  return env === 'dev' ? 'test' : env
}

export const getGovHubUrl = (env = GSG_ENVIRONMENT) => {
  return env === 'prod'
    ? 'https://govhub.com'
    : `https://${getPscServiceEnvironment(env)}.govhub.com`
}

export const getPayHubUrl = (env = GSG_ENVIRONMENT) => {
  return env === 'prod'
    ? 'https://pay-hub.net'
    : `https://${getPscServiceEnvironment(env)}.pay-hub.net`
}

export const getCountyTaxesUrl = (env = GSG_ENVIRONMENT) => {
  return env === 'prod'
    ? 'https://county-taxes.net'
    : `https://${getPscServiceEnvironment(env)}.county-taxes.net`
}

/**
 * Sets the global {@link GSG_ENVIRONMENT} variable to the
 * passed environment.
 * @throws Error if the environment is invalid.
 */
export const setEnvironment = environment => {
  if (!isValidEnvironment(environment)) {
    throw new Error(`"${environment}" is not a valid environment`)
  }

  GSG_ENVIRONMENT = environment

  if (process.env?.GSG_ENVDC) {
    GSG_ENVIRONMENT = getEnvFromEnvdc(process.env.GSG_ENVDC)
  }

  pscServiceEnvironment = getPscServiceEnvironment()

  baseGovHubUrl = getGovHubUrl()
  basePayHubUrl = getPayHubUrl()
  baseCountyTaxesUrl = getCountyTaxesUrl()

  baseEWalletUrl = GSG_ENVIRONMENT === 'prod'
    ? 'https://e-wallet.grantstreet.com'
    : `https://${pscServiceEnvironment}-e-wallet.grantstreet.com`

  // Keep in sync with getCardInputUrl in EW. If the slugs change then you'll
  // need to look at them both.
  // e-wallet-vue/unit/tests/unit/getCardInputUrl.spec.js has tests that cover
  // this portion.
  if (GSG_ENVIRONMENT === 'prod') {
    if (
      // In the context of e2e tests (which don't have a window), default to
      // prod3
      typeof window !== 'undefined' &&
      /^www2/.test(window.location.hostname || '')
    ) {
      baseDVUrl = 'https://www2.vault.county-taxes.com/vault'
    }
    else {
      baseDVUrl = 'https://vault.county-taxes.com/vault'
    }
  }
  else if (GSG_ENVIRONMENT === 'dev' || GSG_ENVIRONMENT === 'test') {
    baseDVUrl = `https://${pscServiceEnvironment}.vault.grantstreet.com/vault`
  }
  else {
    baseDVUrl = `https://${pscServiceEnvironment !== 'demo' ? pscServiceEnvironment : 'stage'}.vault.county-taxes.com/vault`
  }
}

export const needsBasicAuth = (environment = GSG_ENVIRONMENT, client) => {
  return environment === 'beta' || environment === 'demo' || (environment === 'prod' && client === 'sunshine')
}

// Returns a boolean indicating whether the current environment is prod
export const isProd = () => {
  if (!GSG_ENVIRONMENT) {
    throw new Error('Tried to access isProd() before GSG_ENVIRONMENT was set')
  }
  return GSG_ENVIRONMENT === 'prod'
}

function getEnvFromEnvdc (envdc) {
  const matches = /^([a-z]+)\d$/.exec(envdc)
  if (matches?.length > 0) {
    return matches[1]
  }

  throw new Error(`Unknown envdc "${envdc}"`)
}

/**
 * TAXSYS URLS
 *
 * Historically on PSP we have conflated the different types of TaxSys URLs
 * based on their different domains, their different purposes, and their
 * different circumstantial use cases. The goal here is to maintain a clear
 * distinction between all of those points.
 *
 * The core underlying all interactions with TaxSys is the TCB Service we
 * communicate with. This is accessible directly as the TCB Service URL,
 * available via getTaxCbsServiceUrl(). This is a service location and a backend
 * entity, not a purpose. It may sometimes be the URL you need for a given
 * purpose or returned from purpose-first functions, but it is important to keep
 * that distinction in mind. (E.G. In the past we would sometimes directly use
 * the TCB service URL as the source of TaxSys iFrames and refer to it in that
 * way.)
 *
 * Our govhub.com and county-taxes.net domains use BigIP to forward requests for
 * /iframe-taxsys/<TCB Service> to the specified TCB Service. This is to avoid
 * CORS issues. These domain forwarding URLs are used for multiple things and so
 * don't have a purpose specific name similar to the TCB service.
 *
 * If you want to hit/link to the TCB service directly use
 * getTaxCbsServiceUrl(). If you want to interact with TaxSys without CORS
 * issues use getTaxsysDomainForwardingUrl(). Everything else here is to keep
 * the construction and purpose of the various parts clear.
 *
 * NOTE: You should probably almost never want the TCB Service URL. If you're
 * not sure, assume you want a forwarding URL and then confirm.
 *
 * Both of these main URLs can have their base overridden via
 * overrideTaxCbsServiceUrl() and overrideTaxsysDomainForwardingUrl()
 * respectively. This should usually be done for sandbox linking/testing in dev
 * or for preventing CORS issues when in EPS mode. When overridden some work
 * will still be done to construct the correct final URL and it's important to
 * note that client Ids in subdomains will be replaced with the configured
 * client Id for the active site. A forwarding URL can contain both overrides.
 * E.g.
 * <taxsysDomainForwardingUrlOverride>/iframe-taxsys/<taxCbsServiceUrlOverride>.hostname
 */

/**
 * The global default TaxSys Client Id string used when constructing URLs.
 * @type string
 */
export let TAXSYS_CLIENT_ID

/**
 * Sets the global default TaxSys Client Id string used when constructing URLs.
 */
export const setTaxsysClientId = taxsysClientId => {
  if (TAXSYS_CLIENT_ID) {
    // TAXSYS_CLIENT_ID shouldn't ever be reset in the wild but clear overrides
    // here just in case
    taxCbsServiceUrlOverride = undefined
    taxCbsServiceUrlOverrideBase = undefined
    taxsysDomainForwardingUrlOverride = undefined
    taxsysDomainForwardingUrlOverrideBase = undefined
  }
  TAXSYS_CLIENT_ID = taxsysClientId
}

// Private. Do not export.
// An optional override for the TCB service URL. It will be set, if present, the
// first time getTaxCbsServiceUrl is called.
let taxCbsServiceUrlOverride
// The raw base for this override. I has to be interpolated with the client id
// and formatted.
let taxCbsServiceUrlOverrideBase

/**
 * Sets a base URL that will be used when creating TCB Service URLs.
 * @param  {string} base - The URL base to be formatted
 */
export const overrideTaxCbsServiceUrl = base => {
  // This can't do the full override process because the client id might not
  // (probably isn't) available yet, so it will be formatted and the master
  // override will be set later.
  taxCbsServiceUrlOverrideBase = base
}

// Private. Do not export.
// An optional override for the TaxSys Domain Forwarding URL. It will be set, if
// present, the first time getTaxsysDomainForwardingUrlOverride is called.
let taxsysDomainForwardingUrlOverride
// The raw base for this override. It may have to be interpolated with the
// client id and formatted.
let taxsysDomainForwardingUrlOverrideBase

// This allows the EPS parent script to dictate that the TS iframe URL matches
// the public site iframe root in prod. You don't want to do this in any other
// situation.
let dangerouslyOverrideTaxsysDomainForwardingUrlInProd

/**
 * Sets a base URL that will be used when creating TaxSys Domain Forwarding
 * URLs. (Takes precedent over the TCB Service URL override.)
 * @param  {string}  base - The URL base to be formatted
 * @param  {boolean} dangerouslyAllowOverrideInProd - Set to true to allow the
 * EPS parent script to dictate that the iframe URL matches the public site
 * iframe root in prod. You don't want to do this in any other situation.
 */
export const overrideTaxsysDomainForwardingUrl = (base, dangerouslyAllowOverrideInProd = false) => {
  // This can't do the full override process because the client id might not
  // (probably isn't) available yet, so it will be formatted and the master
  // override will be set later.
  taxsysDomainForwardingUrlOverrideBase = base
  dangerouslyOverrideTaxsysDomainForwardingUrlInProd = dangerouslyAllowOverrideInProd
}

/**
 * Formats a passed base URL with the provided client id and strips ts iframe
 * forwarding slugs if present. (For internal use. Do not export.)
 * @param  {string} base           - The URL base to be formatted
 * @param  {string} taxsysClientId - The TaxSys client id
 */
const formatTaxsysOverrideUrl = (base, taxsysClientId) => {
  // Explicit string interpolation might be the more reliable method
  if (/%s/.test(base)) {
    base = base.replaceAll(/%s/g, taxsysClientId)
  }
  // If interpolation place wasn't specified then we match client ids ourselves
  // and replace them
  else {
    // If it's a dev sandbox or county-taxes.com URL and the path matches a
    // structure containing a client id replace the supplied client id with the
    // one configured by us.
    //
    // E.g. All of these URLs would get sanbernardino (with or without the state
    // suffix) replaced by the passed client id.
    // https://tcbdev010-dev3-sanbernardino-ca.county-taxes.com:11353
    // https://tcbdev010-dev3-sanbernardino-ca.county-taxes.com:11353/iframe-taxsys
    // https://tcbdev010-dev3-sanbernardino-ca.county-taxes.com:11353/iframe-taxsys/foo
    // https://tcbdev010-dev3-sanbernardino-ca.county-taxes.com:11353/public/property_tax/bill_numbers/20230000232/tax_dollar_breakdown
    // https://tcbdev010-dev3-sanbernardino.county-taxes.com:11353
    // tcbdev010-dev3-sanbernardino-ca.county-taxes.com:11353
    // dev3-sanbernardino-ca.county-taxes.com:11353
    // beta-sanbernardino-ca.county-taxes.com:11353
    // beta-sanbernardino.grantstreet.com:11353
    // sanbernardino-ca.grantstreet.com:11353
    // https://tcbdev010-dev3.grantstreet.com:11353
    // See the tests for more.
    // Generally, the URL is considered in parts, most of which are
    // optional:
    // (scheme://)?(server-|envdc-)?(env-)?(existing client id)?.(rest of hostname)(:port)
    base = base.replace(/^((?:[^:]+:\/\/)?(?:[a-z]+\d+-?)*(?:dev-?|test-?|beta-?|demo-?|stage-?)?)[^.]*(\.(?:grantstreet|county-taxes).com(?:\W.*)?)$/i,
      (_, prefix, suffix) => {
        // In the case of
        // `https://tcbdev010-dev3.grantstreet.com:11353`, there's no
        // additional dash to capture in the regex, so we need this
        // replace function to be able to add one.
        if (prefix && !prefix.endsWith('-')) {
          prefix += '-'
        }
        return `${prefix}${taxsysClientId}${suffix}`
      },
    )
  }

  // Strip an /iframe-taxsys and anything after it, if present
  return base.replace(/^([^.]*\w+\d?-.*\.(?:grantstreet|county-taxes).com(?:\W.*)?)\/iframe-taxsys\b.*$/i, '$1')
}

/**
 * Gets the TCB Service URL override if set. (For internal use. Do not export.)
 * @param   {string} [taxsysClientId=TAXSYS_CLIENT_ID] - The TaxSys client id to
 * interpolate if necessary
 * @returns {string|undefined} - Returns a string or undefined
 */
const getTaxCbsServiceUrlOverride = (taxsysClientId = TAXSYS_CLIENT_ID) => {
  if (!taxsysClientId) {
    throw new TypeError('TAXSYS_CLIENT_ID has not been initialized and is required.')
  }
  if (!taxCbsServiceUrlOverrideBase) {
    taxCbsServiceUrlOverride = undefined
    return undefined
  }

  // If the override was already set and the default clientId was passed return
  // the existing override
  // (TAXSYS_CLIENT_ID shouldn't ever be reset in the wild.)
  if (taxCbsServiceUrlOverride && taxsysClientId === TAXSYS_CLIENT_ID) {
    return taxCbsServiceUrlOverride
  }

  // Format an override URL from the base and client id
  const override = formatTaxsysOverrideUrl(taxCbsServiceUrlOverrideBase, taxsysClientId)

  // If this is the first time this function was called with the default client
  // id then set the master override
  if (taxsysClientId === TAXSYS_CLIENT_ID) {
    taxCbsServiceUrlOverride = override
  }

  return override
}

/**
 * Gets the TaxSys Domain Forwarding URL override base if set. (For internal
 * use. Do not export.)
 * @param   {string} [taxsysClientId=TAXSYS_CLIENT_ID] - The TaxSys client id to
 * interpolate if necessary
 * @returns {string|undefined} - Returns a string or undefined
 */
const getTaxsysDomainForwardingUrlOverride = (taxsysClientId = TAXSYS_CLIENT_ID) => {
  if (!taxsysClientId) {
    throw new TypeError('TAXSYS_CLIENT_ID has not been initialized and is required.')
  }
  if (!taxsysDomainForwardingUrlOverrideBase) {
    taxsysDomainForwardingUrlOverride = undefined
    return undefined
  }

  // If the override was already set and the default clientId was passed return
  // the existing override
  if (taxsysDomainForwardingUrlOverride && taxsysClientId === TAXSYS_CLIENT_ID) {
    return taxsysDomainForwardingUrlOverride
  }

  // Format an override URL from the base and client id
  const override = formatTaxsysOverrideUrl(taxsysDomainForwardingUrlOverrideBase, taxsysClientId)

  // If this is the first time this function was called with the default client
  // id then set the master override
  if (taxsysClientId === TAXSYS_CLIENT_ID) {
    taxsysDomainForwardingUrlOverride = override
  }

  return override
}

/**
 * Returns the county-taxes.com URL for this env. (For internal use. Do not
 * export.)
 * @param {string} [env=GSG_ENVIRONMENT]              - The environment string
 * @param {string} [taxsysClientId=TAXSYS_CLIENT_ID]  - The client id
 * @returns {string} - The county-taxes.com URL
 */
const getCountyTaxesDotComUrl = (env = GSG_ENVIRONMENT, taxsysClientId = TAXSYS_CLIENT_ID) => {
  if (!env) {
    throw new Error('GSG_ENVIRONMENT has not been initialized and is required.')
  }
  if (!taxsysClientId) {
    throw new TypeError('TAXSYS_CLIENT_ID has not been initialized and is required.')
  }
  return env === 'prod'
    ? `https://${taxsysClientId}.county-taxes.com`
    : `https://${getPscServiceEnvironment(env)}-${taxsysClientId}.county-taxes.com`
}

/**
 * Returns the proxy domain for TaxSys domain forwarding for this env.
 * @param {string} [env=GSG_ENVIRONMENT] - The environment string
 * @returns {string} - The URL
 */
export const getTaxsysForwardingProxyUrl = (env = GSG_ENVIRONMENT) => {
  if (!env) {
    throw new Error('GSG_ENVIRONMENT has not been initialized and is required.')
  }
  // In some cases we need to make sure that we load TaxSys iframes from the
  // same origin that we're currently on to avoid CORS issues
  const proxyDomain = /[^.]*[/.]govhub\.com/.test(window.location.origin) ? 'govhub.com' : 'county-taxes.net'

  return env === 'prod'
    ? `https://${proxyDomain}`
    : `https://${getPscServiceEnvironment(env)}.${proxyDomain}`
}

/**
 * Returns the TaxSys (TCB) Service URL for this env and client. This may be
 * configured by explicit override, otherwise it will default to the
 * county-taxes.com URL. (This is usually not the URL you want. That's almost
 * always getTaxsysDomainForwardingUrl().)
 * @param {string} [env=GSG_ENVIRONMENT]              - The environment string
 * @param {string} [taxsysClientId=TAXSYS_CLIENT_ID]  - The client id
 * @returns {string} - The TaxSys (TCB) Service URL
 */
export const getTaxCbsServiceUrl = ({ env = GSG_ENVIRONMENT, taxsysClientId = TAXSYS_CLIENT_ID } = {}) =>
  getTaxCbsServiceUrlOverride(taxsysClientId) || getCountyTaxesDotComUrl(env, taxsysClientId)

/**
 * Generates the TaxSys Domain Forwarding URL based on the provided parameters.
 *
 * @param   {string}  [env=GSG_ENVIRONMENT] - The environment string
 * @param   {string}  [taxsysClientId=TAXSYS_CLIENT_ID] - The TaxSys client id
 * to interpolate
 * @returns {string} - The generated TaxSys Domain Forwarding URL
 */
export const getTaxsysDomainForwardingUrl = ({ taxsysClientId = TAXSYS_CLIENT_ID, env = GSG_ENVIRONMENT } = {}) => {
  if (!env) {
    throw new Error('GSG_ENVIRONMENT has not been initialized and is required.')
  }
  if (!taxsysClientId) {
    throw new TypeError('TAXSYS_CLIENT_ID has not been initialized and is required.')
  }

  let base
  // Respect override
  if (taxsysDomainForwardingUrlOverrideBase) {
    // This is here because the upstream override functions don't have access to
    // the env. It uses the passed env, not isProd because this could be called
    // before GSG_ENVIRONMENT is set.
    if (env === 'prod' && dangerouslyOverrideTaxsysDomainForwardingUrlInProd !== true) {
      throw new Error('The TaxSysDomainForwardingUrl was incorrectly overridden in prod. This should NOT happen.')
    }
    base = getTaxsysDomainForwardingUrlOverride(taxsysClientId)
  }
  // Or use the default
  else {
    base = getTaxsysForwardingProxyUrl(env)
  }

  // Add domain forwarding slug and the TCB service hostname slug
  let hostnameSlug
  try {
    hostnameSlug = new URL(getTaxCbsServiceUrl({ env, taxsysClientId })).hostname
  }
  catch {
    throw new TypeError('Could not fully parse supplied TCB Service URL.')
  }

  return joinUrl(base, 'iframe-taxsys', hostnameSlug)
}

// This is used by initWidgetEnvWithFallback to avoid re-logging the fact that a
// site is not passing an environment to Kibana when multiple widgets are being
// attached on the same page. Currently this only applies to Announcements.
const alreadyLoggedLegacy = {}

// Sets the current environment for an embeddable PSC widget, falling back to an
// environment detected through legacy means if necessary. This is a temporary
// helper function being used while external "parent" apps are in the process of
// passing explicit environment parameters to PSC widgets. If an explicit
// environment was not passed, this will use the passed legacy environment and
// log the incident to Kibana.
//
// This does not need to be used for single-page applications because those can
// flip over to using the /environment endpoint method in a single release
// without legacy support.
//
// TODO PSC-9164: Remove this when all parents are explicitly passing an
// environment.
export const initWidgetEnvWithFallback = ({
  explicitEnvironment,
  urlForLegacyEnvironment,
  legacyEnvironmentOverride,
  diagApp,
  diagSite,
  // This is injected to avoid dependency cycles:
  RequestApi,
  exceptionLogger,
}) => {
  if (typeof explicitEnvironment === 'undefined') {
    const legacyEnvironment = legacyEnvironmentOverride ||
      getEnvFromUrlLegacy(urlForLegacyEnvironment)

    // This means the parent site is not yet explicitly passing the
    // `environment` attach parameter to the current widget, so use the widget's
    // legacy environment-detection method (likely URL-matching). This can be
    // removed once all sites using the widget are passing the environment.
    setEnvironment(legacyEnvironment)

    // If we have already logged the legacy notice to Kibana for this app, skip
    // logging it again.
    if (alreadyLoggedLegacy[diagApp]) {
      return
    }
    alreadyLoggedLegacy[diagApp] = true

    // Log the fact that this site isn't passing the environment to Kibana. This
    // will help PSC know when all sites are using the new explicit environment
    // parameter so we can remove the legacy fallback.
    const requestApi = new RequestApi({
      app: diagApp,
      env: legacyEnvironment,
      exceptionLogger,
    })
    requestApi.diag({
      ...diagSite ? { site: diagSite } : {},
      diagnostics: {
        usingLegacyEnvironment: true,
      },
    })
    return
  }
  if (!isValidEnvironment(explicitEnvironment)) {
    throw new Error(`Invalid "environment" parameter. Passed value: "${explicitEnvironment}". Expected "dev", "test", "stage", "beta", "demo", or "prod".`)
  }

  setEnvironment(explicitEnvironment)
}

/**
 * This is a legacy method of determining the current environment based on URL
 * matching. It is not scalable and should only be used via
 * initWidgetEnvWithFallback, which should only be used for EXISTING widgets
 * that are in the process of being migrated to use explicit environments.
 */
function getEnvFromUrlLegacy (url) {
  if (!url) {
    // We won't have a url for isProd() checks in E2E testing
    if (typeof window === 'undefined') {
      return 'dev'
    }
    else {
      url = window.location.hostname
    }
  }
  // If we were passed in a url, make sure the protocol is stripped
  else {
    url = url.replace(/^https?:\/\//, '')
  }

  if (url === 'pay-hub.net' ||
    url === 'e-wallet.grantstreet.com' ||
    url === 'payment-express.net' ||
    url === 'renewexpress.com' ||
    url === 'county-taxes.net') {
    return 'prod'
  }

  if (/^govhub.com/.test(url)) {
    return 'prod'
  }

  if (/^[a-z]+(?:-[a-z]{2})?\.(?:taxsys\.net|county-taxes\.com)/.test(url)) {
    return 'prod'
  }

  if (/netlify\.(app|com)/.test(url)) {
    return 'beta'
  }

  // Parse out the environment
  // Matches  (<env>)-(payhub|e-wallet).grantstreet.com
  //          (<env>).pay-hub.net
  //          (<env>).payment-express.net
  //          (<env>).renewexpress.com
  //          (<env>).county-taxes.net
  //          (<env>)-<county>.taxys.net
  //      or  (<env>)-<county>.county-taxes.com
  const matches = /(?:^|\.)([a-z]+)\d?(?:-(?:payhub|e-wallet)\.grantstreet\.com|\.pay-hub\.net|\.govhub\.com.*|\.payment-express\.net|\.renewexpress\.com|\.county-taxes\.net|-[a-z]+(?:-[a-z]{2})?\.(?:taxsys\.net|county-taxes\.com))/g.exec(url)

  if (matches && matches.length === 2) {
    // The 2 from www2 has already been parsed out by the above regex
    return matches[1] === 'www' ? 'prod' : matches[1]
  }

  // That won't have matched for WDS or sandboxes
  return 'dev'
}

// Returns a boolean indicating if the url would take the user to a govhub page.
export const isUrlInternal = (url) => {
  return url.match(/^https:\/\/(.+)?(govhub\.com|county-taxes\.net|pay-hub\.net)/)
}
