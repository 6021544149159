import { defaultConfig } from '@formkit/vue'
import { en, es } from '@formkit/i18n'
import { sentryException } from '../../src/sentry.js'

import {
  bootstrapTheme,
  createResponsiveI18nPlugin,
  standardFormDataPlugin,
  gsgInputsPlugin,
} from '@grantstreet/formkit'

export const setupFormKitConfig = (locale) => defaultConfig({
  config: {
    // This adds the "horizontal" Bootstrap theme, which places labels in a
    // column to the left of their inputs. This is the GSG standard.
    // Alternatively you can use bootstrapTheme.verticalClasses to place
    // labels vertically above their inputs (see "Bootstrap Theme" below).
    classes: bootstrapTheme.horizontalClasses,

    // This is used by the gsgInputsPlugin for logging errors associated with
    // our custom inputs
    exceptionLogger: sentryException,
  },
  locales: { en, es },
  locale: locale.value,
  plugins: [
    createResponsiveI18nPlugin(locale),
    standardFormDataPlugin,

    // If you would like to use any of the GSG FormKit inputs (listed below in
    // this README):
    gsgInputsPlugin,
  ],
})
