import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import type { App } from 'vue'

import searchStore from './store/index.js'
import type { Store } from 'vuex'

sanitizeConfig.namedConfigurations = {
  confidential: {
    USE_PROFILES: {
      html: true,
      svg: true,
    },
  },
}
sanitizeConfig.default.ALLOWED_TAGS.push('img')

export default function install (app: App<Element>, { store } : { store: Store<Record<string, unknown>>}) {
  initBootstrapVue(app)

  app.use(VueDOMPurifyHTML, sanitizeConfig)

  if (!store.hasModule('IndexSearch')) {
    store.registerModule('IndexSearch', searchStore)
  }

  return true
}
