import {
  type AsyncComponentLoader,
  type Component,
  defineAsyncComponent,
  h,
} from 'vue'
import ModuleLoadError from '../components/ModuleLoadError.vue'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import sleep from '@grantstreet/psc-js/utils/sleep.js'

// These two files need to stay separate. See the other for details.
import { getAsyncImportDebounceFunction } from './dynamic-imports.ts'

type AsyncComponentResolveResult = Awaited<ReturnType<AsyncComponentLoader<Component>>>

export const defineAsyncComponentWithHandlers = (
  loader: () => Promise<AsyncComponentResolveResult>,
  {
    largeError = false,
    onFail,
    errorComponent = h(ModuleLoadError, { large: largeError }),
  }: {
    largeError?: boolean
    onFail?: (error: Error) => unknown
    errorComponent?: Component
  } = {},
) => {
  // This function is created once per definition which means that if a
  // component fails and later the same definition is re-fetched it will attempt
  // to fetch the first time but will immediately error if that request fails
  // since it can't debounce again. This is desired since we don't want to retry
  // things many times if they've previously failed.
  const getDebounce = getAsyncImportDebounceFunction()

  return defineAsyncComponent({
    loader,
    loadingComponent: LoadingBars,
    errorComponent,
    // Retry up to three times then reject
    onError: async (error, retry, fail) => {
      try {
        await sleep(getDebounce())
      }
      catch {
        // We hit the max retries so this is a complete failure
        fail()
        await onFail?.(error)
        return
      }
      return retry()
    },
  })
}
